import Time from "../../Matches/Time";
import "./Postponed.css";

const Postponed = ({ match_hour }) => {
  return (
    <div className="container__Postponed">
      <div className="square__Postponed">
        <div className="text__Postponed">Aplazado</div>
      </div>
    </div>
  );
};

export default Postponed;
