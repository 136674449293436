import "./MobileShare.css";
import { Spinner } from "react-bootstrap";
import SocialIcons from "../../../components/Share/SocialIcons";
import { Backdrop } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CopyAnimation from "../CopyAnimation";
import { useState } from "react";

const MobileShare = ({
  setReady,
  ready,
  linkUrl = false,
  imageUrl = false,
  title = "Share link with your friends!",
  platforms = [],
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(linkUrl);
      setIsCopied(true);
    } catch (err) {
      console.log(err);
    }
  };

  if (!linkUrl && !imageUrl) {
    <Spinner animation="border" style={{ color: "rgba(121, 192, 0)" }} />;
  }

  return (
    <div className="fadedBackground__MobileShare">
      <div className="container__MobileShare">
        <button
          onClick={() => setReady(false)}
          className="exitBtn__MobileShare"
        >
          <CloseIcon />
        </button>
        <div>
          <h6 className="title__MobileShare" id="transition-modal-title">
            {title}
          </h6>
        </div>
        <div className="copy_link_box__MobileShare">
          <div
            className="url2Share__MobileShare"
            id="transition-modal-description"
          >
            <a href={linkUrl} target="_blank" rel="noreferrer">
              {linkUrl}
            </a>
          </div>
          <div onClick={copyToClipBoard} className="copyBtn__MobileShare">
            {isCopied ? <CopyAnimation /> : <div>Copiar</div>}
          </div>
        </div>
        <div className="socialIcons_container__MobileShare">
          <SocialIcons linkUrl={linkUrl} platforms={platforms} />
        </div>
      </div>
      <Backdrop
        sx={{
          color: "#000",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={ready}
        onClick={() => setReady(false)}
      />
    </div>
  );
};

export default MobileShare;
