import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { UserContext } from "../contexts/UserContext";
import PagesEnum from "../enums/pages";
import { LOGIN_URL_PROD } from "../environment";

const Banner = ({ banner, pageName }) => {
  const isMobileScreen = useMediaQuery({
    query: "(max-width: 600px)",
  });

  const { user } = useContext(UserContext);

  const pressBannerHandler = () => {
    if (user) {
      window.location.assign(
        "https://m.codere.com.co/deportescolombia/#/CashierPage?partner=mvp"
      );
      window.top.location.href =
        "https://m.codere.com.co/deportescolombia/#/CashierPage?partner=mvp";
      window.parent.location =
        "https://m.codere.com.co/deportescolombia/#/CashierPage?partner=mvp";
      return;
    }
    window.location.assign(`${LOGIN_URL_PROD}`);
    window.top.location.href = `${LOGIN_URL_PROD}`;
    window.parent.location = `${LOGIN_URL_PROD}`;
    return;
  };

  return (
    <div
      className="d-flex justify-content-center"
      style={{
        marginLeft:
          pageName === PagesEnum.parlays && !isMobileScreen ? "110px" : "0px",
      }}
    >
      <img
        className="d-block my-2 banner"
        src={banner}
        alt="banner"
        onClick={pressBannerHandler}
        style={{
          cursor: "pointer",
          height: !isMobileScreen ? "171px" : "82px",
        }}
      />
    </div>
  );
};

export default Banner;
