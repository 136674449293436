import React, { useContext } from "react";
import { useHistory } from "react-router";
import CostumButton from "./GeneralComponents/Button";
import { UserContext } from "../src/contexts/UserContext";

function Account() {
  const { user, setUser } = useContext(UserContext);

  const history = useHistory();
  function logOut() {
    localStorage.removeItem("jwt-token");
    history.push("/signup");
    setUser(false);
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <CostumButton>
            <button style={{marginTop: "10em"}} onClick={logOut} className="button login-btn">
              Log out
            </button>
          </CostumButton>
        </div>
      </div>
    </div>
  );
}

export default Account;
