import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./fonts/High-School.ttf";
import "./fonts/HighSchoolEURSans-Condensed-V2.1.ttf";

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import ScrollToTop from "./GeneralComponents/ScrollToTop";

import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "jquery/dist/jquery";
import "popper.js/dist/popper";
import "bootstrap/dist/js/bootstrap";
import axios from "axios";
import { UserProvider } from "./contexts/UserContext";
import { ResultsScheduleProvider } from "./contexts/ResultsScheduleContext";
import { AccumulatorProvider } from "./contexts/AccumulatorContext";
import { PredictionsProvider } from "./contexts/PredictionsContext";
import ReactGA from "react-ga";

ReactGA.initialize("UA-204804134-2", {
  useExistingGa: true,
  debug: false,
  // titleCase: false,
  // gaOptions: {
  //   userId: 123,
  // },
  // cookieFlags: "SameSite=None; Secure",
});
ReactGA.ga("create", "UA-204804134-2", "auto", {
  cookieFlags: "SameSite=None; Secure",
});

ReactGA.pageview(window.location.pathname + window.location.search);

ReactGA.initialize("UA-204804134-2", {
  useExistingGa: true,
  debug: true,
  // titleCase: false,
  // gaOptions: {
  //   userId: 123,
  // },
  // cookieFlags: "SameSite=None; Secure",
});
ReactGA.ga("create", "UA-204804134-2", "auto", {
  cookieFlags: "SameSite=None; Secure",
});

ReactGA.pageview(window.location.pathname + window.location.search);

const jwt = localStorage.getItem("jwt-token");
jwt && (axios.defaults.headers.common["jwt-token"] = jwt);

//mobile: username=(userName)&isMobile=1"
//web: username={userName}&temptoken={tempToken}&language={lang}
const urlParams = new URLSearchParams(window.location.search);

const tokenParam = urlParams.get("token");

if (
  tokenParam &&
  +tokenParam !== 0 &&
  tokenParam !== "null" &&
  tokenParam !== "undefined"
) {
  sessionStorage.setItem("jwt-token", tokenParam);
} else {
  sessionStorage.removeItem("jwt-token");
  localStorage.removeItem("jwt-token");
  axios.defaults.headers.common["jwt-token"] = null;
}

const languageParam = urlParams.get("language");
languageParam && sessionStorage.setItem("language", languageParam);

const isMobileParam = urlParams.get("isMobile");
isMobileParam && sessionStorage.setItem("isMobile", isMobileParam);

const osParam = urlParams.get("os");
isMobileParam && sessionStorage.setItem("os", osParam);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop>
        <UserProvider>
          <ResultsScheduleProvider>
            <PredictionsProvider>
              <AccumulatorProvider>
                <App urlParamsInstance={urlParams} />
              </AccumulatorProvider>
            </PredictionsProvider>
          </ResultsScheduleProvider>
        </UserProvider>
      </ScrollToTop>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
