const PagesEnum = {
  welcome: "welcome",
  predictions: "predictions",
  results: "results",
  termsAndCond: "termsAndCond",
  howToPlay: "howToPlay",
  parlays: "parlays",
  leaderboard: "leaderboard",
};

module.exports = PagesEnum;
