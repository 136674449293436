import React, { useContext } from "react";
import { Modal, Fade, makeStyles, Backdrop } from '@material-ui/core'
import { PredictionsContext } from "../contexts/PredictionsContext";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "20px",
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    container_btn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '20px'
    },
    btn: {
        '&:hover, &active, &focus': {
            backgroundColor: "#7ebc15",
            color: 'white'
        },
        width: '80px',
        height: '30px',
        border: '1px solid #7ebc15',
        borderRadius: '20px',
        color: '#7ebc15',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));
const ModalExit = ({ ready, setReady, back }) => {
    const { setModify } = useContext(PredictionsContext);
    const classes = useStyles();
    const handleClose = () => setReady(false);
    const handleLeave = () => {
        back();
        setModify(false);
        setReady(false)
    }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={ready}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={ready}>
                <div className={classes.paper}>
                    <h4 className="text-center" id="transition-modal-title">Quit from the page?</h4>
                    <p id="transition-modal-description">All progress in this page will be lost.</p>
                    <div className={classes.container_btn}>
                        <div onClick={handleLeave} className={classes.btn}><p className="pt-3">LEAVE</p></div>
                        <div onClick={handleClose} className={classes.btn}><p className="pt-3">STAY</p></div>
                    </div>
                </div>
            </Fade>
        </Modal>

    );
}

export default ModalExit;