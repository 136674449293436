import React from "react";
import MVPCodereForm from "../images/header/MVPCodereForm.png";

const headerStyle = {
  backgroundColor: "#353D49",
  textAlign: "center",
  padding: "3em",
};

function VipFormHeader() {
  return (
    <>
      <div style={headerStyle}>
        <img src={MVPCodereForm} alt="mvp logo" />
      </div>
    </>
  );
}

export default VipFormHeader;
