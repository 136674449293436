import lottie from "lottie-web";
import { useEffect, useRef } from "react";

import copyAnimation from "../../assets/animations/copy_animation.json";

const CopyAnimation = () => {
  const anime = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: anime.current,
      renderer: "svg",
      loop: false,
      animationData: copyAnimation,
    });
    return () => lottie.stop();
  }, []);
  return (
    <div style={{ marginTop: "0.6rem" }}>
      <div
        ref={anime}
        style={{
          width: "50px",
          height: "100%",
        }}
      />
    </div>
  );
};

export default CopyAnimation;
