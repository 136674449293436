import React from "react"

function HeaderOU() {
  return (
    <div className="container">
      <div className="container accum-matches-box-headers">
        <div className="row mb-2">
          <div className="col-5"></div>
          <div className="col text-center">MÁS</div>
          <div className="col text-center">MENOS</div>
        </div>
      </div>
    </div>
  )
}

export default HeaderOU
