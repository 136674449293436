import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Fade } from '@material-ui/core';
import isNullOrUndefined from './../helpers/is-null-or-undefined';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "13.9rem",
        height: "18.6rem",
        backgroundColor: "white",
        boxShadow: theme.shadows[5],
        borderRadius: ".7rem",
        padding: "2rem 6px",
        textAlign: "center",
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
    },
    optionsList: {
        height: "100%",
        overflowY: "auto",
    }
}));

export default function ModalPicker({ options, onCloseModal, onSelectOption, selectedOption }) {
    const classes = useStyles();

    const body = (
        <Fade in={!!options}>
            <div className={classes.paper}>
                <div className={classes.optionsList + " styled-scrollbar"}>
                    {options?.map(option => {
                        const isSelected = !isNullOrUndefined(selectedOption) && selectedOption === option?.value;
                        return (
                            <div style={isSelected ?
                                { backgroundColor: "#F8F8F8", color: "#79C000", fontWeight: "600" }
                                : {}}>
                                <button
                                    onClick={() => { onSelectOption(option); onCloseModal() }}
                                    className="leaderboard-period-picker_option"
                                >
                                    {option.displayValue}
                                </button>
                            </div>)
                    }
                    )}
                </div>
            </div>
        </Fade>

    );

    return <Modal
        open={!!options}
        onClose={onCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
    >
        {body}
    </Modal>
}
