import React, { useEffect, useState } from "react";
import Date from "./Date";
import Teams from "./Teams";
import Team from "./Team";
import Prediction from "./Prediction";
import Time from "./Time";
import ScoreBox from "../ResultsSchedule/ScoreBox";
import isNullOrUndefined from "./../helpers/is-null-or-undefined";
import MatchSuccessPoints from "./MatchSuccessPoints";
import MediaQuery from "react-responsive";
import {
  hasMatchEndedYet,
  hasMatchStartedYetByCode,
} from "../helpers/match-status";
import Postponed from "../components/Postponed/Postponed";

function Match({
  homeTeamName,
  awayTeamName,
  filterdDates,
  match_start,
  match_hour,
  matchId,
  getPrediction,
  index,
  homeTeamShirt,
  awayTeamShirt,
  matchScore,
  nextRound,
  editMode,
  userAddedPrediction,
  savedPrediction,
  statusCode,
  lastRound,
}) {
  //setting a prediction of a single match
  const [prediction, setPrediction] = useState({
    matchId: matchId,
    homeGoals:
      userAddedPrediction?.home_score ?? savedPrediction?.homeGoals ?? null,
    awayGoals:
      userAddedPrediction?.away_score ?? savedPrediction?.awayGoals ?? null,
  });

  //a callback function from matches
  useEffect(() => {
    getPrediction(prediction, index);
  }, [prediction]);

  useEffect(() => {
    if (userAddedPrediction) {
      setPrediction({
        matchId: matchId,
        homeGoals: userAddedPrediction?.home_score,
        awayGoals: userAddedPrediction?.away_score,
      });
    }
  }, [userAddedPrediction]);

  //getting home goals
  useEffect(() => {
    if (userAddedPrediction) {
      setPrediction({
        matchId: matchId,
        homeGoals: userAddedPrediction?.home_score,
        awayGoals: userAddedPrediction?.away_score,
      });
    }
  }, [userAddedPrediction]);

  const setHomeGoals = (goals) => {
    if (goals || goals === 0) {
      setPrediction((prevPrediction) => {
        return { ...prevPrediction, homeGoals: goals };
      });
    }
  };

  const setAwayGoals = (goals) => {
    if (goals || goals === 0) {
      setPrediction((prevPrediction) => {
        return { ...prevPrediction, awayGoals: goals };
      });
    }
  };

  const hasMatchEnded = hasMatchEndedYet(statusCode);
  const hasMatchStarted = hasMatchStartedYetByCode(statusCode);

  const isPostponed = statusCode === 4;

  return (
    <div className="py-0 mb-2 col-md-6" style={{ display: "inline-block" }}>
      <MediaQuery maxWidth={768}>
        {filterdDates && <Date filterdDates={filterdDates}></Date>}
      </MediaQuery>
      <Teams>
        <MediaQuery minWidth={768}>
          <div style={{ textAlign: "center" }}>
            <Date match_start={match_start}></Date>
          </div>
        </MediaQuery>
        {isPostponed && <Postponed />}
        <div
          className="match-box container-fluid d-flex justify-content-between align-items-center px-0 position-relative overflow-hidden"
          style={{ opacity: isPostponed ? "0.3" : "1" }}
        >
          {(hasMatchEnded && userAddedPrediction?.successPoints && (
            <MatchSuccessPoints points={userAddedPrediction?.successPoints} />
          )) ||
            null}
          <Team
            homeTeamShirt={homeTeamShirt}
            homeTeamName={homeTeamName}
          ></Team>
          {/* //if match hasn't started. else: scoreBox */}

          {matchScore ? (
            <ScoreBox
              userHomePrediction={userAddedPrediction?.home_score}
              userAwayPrediction={userAddedPrediction?.away_score}
              realResultAwayTeam={matchScore?.away_score}
              realResultHomeTeam={matchScore?.home_score}
              hasMatchEnded={hasMatchEnded}
              hasMatchStarted={hasMatchStarted}
              statusCode={statusCode}
            />
          ) : (
            <div
              style={{ width: "10em" }}
              className="d-flex p-1 prediction-time justify-content-between"
            >
              <Prediction
                disabled={
                  lastRound ||
                  !nextRound ||
                  (!isNullOrUndefined(userAddedPrediction?.home_score) &&
                    !editMode)
                }
                matchId={matchId}
                goals={prediction?.homeGoals}
                setGoals={setHomeGoals}
                postponed={isPostponed}
              />
              <div className="time-and-vs" style={{ height: "100px" }}>
                <Time match_hour={match_hour} />
                {!isPostponed && (
                  <div className="vs-wrapper">
                    <h1 className="vs">VS</h1>
                  </div>
                )}
              </div>

              <Prediction
                disabled={
                  lastRound ||
                  !nextRound ||
                  (!isNullOrUndefined(userAddedPrediction?.away_score) &&
                    !editMode)
                }
                matchId={matchId}
                goals={prediction?.awayGoals}
                setGoals={setAwayGoals}
                postponed={isPostponed}
              />
            </div>
          )}

          <Team
            awayTeamShirt={awayTeamShirt}
            awayTeamName={awayTeamName}
          ></Team>
        </div>
      </Teams>
    </div>
  );
}

export default React.memo(Match);
