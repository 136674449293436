export const PlatformsUrl = {
  facebook: "facebook",
  whatsapp: "whatsapp",
  gmail: "gmail",
};

export const platformShareHandler = (platform, shareLink, text) => {
  let url;
  switch (platform) {
    case PlatformsUrl.facebook:
      url = `https://www.facebook.com/sharer/sharer.php?u=${shareLink}&quote=${text ? text : ""}`;
      window.open(url, "sharer", "toolbar=0,status=0,width=648,height=395");
      break;
    case PlatformsUrl.whatsapp:
      url = `https://api.whatsapp.com/send/?text=${text ? text + "%0A" : ""}${shareLink}`;
      window.open(url, "sharer", "toolbar=0,status=0,width=648,height=395");
      break;
    case PlatformsUrl.gmail:
      url =
        "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=MVP+De+Codere+Mexico&body=" +
        `${text ? text + "%0A" : ""}${shareLink}` +
        "&ui=2&tf=1&pli=1";
      window.open(url, "sharer", "toolbar=0,status=0,width=648,height=395");
      break;
    default:
      break;
  }
};
