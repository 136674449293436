import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { ResultsScheduleContext } from "../contexts/ResultsScheduleContext";
import { useLocation, Link } from "react-router-dom";
import arrowBack from "../images/results/arrowBack.png";
import moment from "moment";
import Team from "../Matches/Team";
import ScoreBox from "./ScoreBox";

import { CURRENT_CAMPAIGN_ENDPOINT } from "../leagues/leagues";
import MatchSuccessPoints from "./../Matches/MatchSuccessPoints";
import RoundSummary from "./../Matches/roundSummary";
import MatchesSuccessIndicators from "./../GeneralComponents/MatchesSuccessIndicators";
import { PredictionsContext } from "./../contexts/PredictionsContext";
import { hasMatchEndedYet } from "./../helpers/match-status";
import { useMediaQuery } from "react-responsive";

const roundStyle = {
  fontWeight: 500,
  paddingTop: "1em",
  marginBottom: 3,
  color: "#979797",
};
const roundDateStyle = {
  fontWeight: 500,
  fontSize: 16,
  paddingBottom: "1em",
};

const roundDateBoxStyle = { boxShadow: "0px 1px 6px rgba(150, 150, 150, 0.3)" };

const arrowBackStyle = {
  position: "absolute",
  marginLeft: "1em",
  marginTop: 25,
};

function ResultDetails() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const param = useParams();

  const { englishPastRoundsPredictions, spanishPastRoundPredictions } =
    useContext(ResultsScheduleContext);

  const { englishCurrentRoundAndSubmission, spanishCurrentRoundAndSubmission } =
    useContext(PredictionsContext);

  const location = useLocation();

  function getCurrentCampaignRound() {
    const keyOfSpanishRound = spanishPastRoundPredictions?.findIndex(
      (round) => {
        return round.round.id === +param.roundId;
      }
    );
    if (keyOfSpanishRound > -1) {
      return spanishPastRoundPredictions[keyOfSpanishRound];
    } else if (+param.roundId === spanishCurrentRoundAndSubmission?.round?.id) {
      return spanishCurrentRoundAndSubmission;
    }
  }

  const submissionResults =
    getCurrentCampaignRound()?.submission?.matchPredictions;
  const checkedPredictions = submissionResults?.reduce(
    (acc, curr) => acc + (curr.status !== "pending" ? 1 : 0),
    0
  );

  const getMatches = getCurrentCampaignRound()?.round?.matches;
  const getRound = getCurrentCampaignRound()?.round?.name;
  const getRoundDate =
    getCurrentCampaignRound()?.round?.matches[0]?.match_start;

  return (
    <div>
      <Link
        to={`/results-schedule/${CURRENT_CAMPAIGN_ENDPOINT}`}
        style={arrowBackStyle}
      >
        <img src={arrowBack} alt="arrow" />
      </Link>
      <div style={roundDateBoxStyle}>
        <p className="text-center" style={roundStyle}>
          JORNADA {getRound}
        </p>
        <p className="round text-center" style={roundDateStyle}>
          {moment(getRoundDate).format("ddd, MMMM D")}
        </p>
      </div>
      <div className="border-bottom">
        {checkedPredictions ? (
          <MatchesSuccessIndicators
            submissionResults={submissionResults}
            matches={getMatches}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="container-fluid" style={{ backgroundColor: "#FAFAFA" }}>
        <div
          style={
            isDesktopOrLaptop
              ? {
                  marginBottom: "6em",
                  background: "#FAFAFA",
                  paddingTop: "1em",
                }
              : {
                  marginBottom: "6em",
                  background: "#FAFAFA",
                  paddingTop: "1em",
                }
          }
          className="container-md"
        >
          {getMatches?.map((match) => {
            const Prediction = submissionResults.find(
              (result) => result.matchId === match.id
            );
            return (
              <div
                key={match.id.toString()}
                className="mb-2 match-box col-md-5 ml-md-5 border justify-content-between overflow-hidden"
                style={{ display: "inline-flex" }}
              >
                {(Prediction?.successPoints && (
                  <MatchSuccessPoints points={Prediction?.successPoints} />
                )) ||
                  null}

                <Team
                  results={true}
                  homeTeamName={match.homeTeam.name}
                  homeTeamShirt={match.homeTeam.shirt_img}
                />
                <ScoreBox
                  userHomePrediction={Prediction?.home_score}
                  userAwayPrediction={Prediction?.away_score}
                  realResultHomeTeam={match.matchScore?.home_score}
                  realResultAwayTeam={match.matchScore?.away_score}
                  hasMatchEnded={hasMatchEndedYet(match.status_code)}
                />
                <Team
                  results={true}
                  awayTeamName={match.awayTeam.name}
                  awayTeamShirt={match.awayTeam.shirt_img}
                />
              </div>
            );
          })}
          {checkedPredictions ? (
            <RoundSummary submission={getCurrentCampaignRound()?.submission} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResultDetails;
