import React from "react";
import Moment from "react-moment";

function Time({ match_hour }) {
  return (
    <p className="mt-2 match-time text-center d-flex">
      <Moment format="LT">{match_hour}</Moment>
    </p>
  );
}

export default Time;
