/* eslint-disable no-restricted-globals */
import ReactGA from "react-ga";
import { deviceDetect } from "react-device-detect";

const isTrueParam = (param) => {
  return param && +param !== 0 && param !== "null" && param !== "undefined";
};

export const paramEventHandler = (
  paramsArray,
  username = null,
  urlParamsInstance
) => {
  const { osName } = deviceDetect();
  const jwt = localStorage.getItem("jwt-token");
  paramsArray.map((param, i) => {
    if (localStorage.getItem("register") && param === "login") return null;
    const checkIsEventSent = localStorage.getItem(param);
    const urlParam = urlParamsInstance.get(param);
    const data = {};
    data[param] = urlParam;
    if (jwt && isTrueParam(urlParam) && username && !checkIsEventSent) {
      console.log(`[helper: paramEventHandler] PostMsg  - ${param}`);
      localStorage.setItem(param, urlParam);
      ReactGA.event({
        category: `${param.toUpperCase()} Success - ${osName}`,
        action: `${username.idInClient} - ${param.toUpperCase()}`,
      });
      if (frames.window.ColCodereAndroid) {
        frames?.window.ColCodereAndroid.postMessage(
          JSON.stringify({
            url: param,
            data: {
              token: localStorage.getItem("jwt-token"),
              username: username.idInClient,
              userid: username.id,
              status: "success",
            },
          })
        );
      }
      window.webkit?.messageHandlers?.observer?.postMessage(
        JSON.stringify({
          url: param,
          data: {
            token: localStorage.getItem("jwt-token"),
            username: username.idInClient,
            userid: username.id,
            status: "success",
          },
        })
      );
      return param;
    } else {
      localStorage.removeItem(param);
      return param;
    }
  });
  return "[helper: paramEventHandler] = DONE ";
};
