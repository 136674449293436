import { hasMatchEndedYet } from '../helpers/match-status';
const predictionsHeaderStyle = {
  marginTop: "1em",
  fontWeight: 500,
  fontSize: 16,
}

const greenIndicator = {
  width: 21,
  height: 13,
  background: "rgb(30 113 4)",
  InnerShadow: "rgba(255, 255, 255, 0.25)",
  boxShadow: "inset 0px 4px 4px rgba(255, 255, 255, 0.25)",
  borderRadius: 5,
  marginRight: 3,
  marginTop: 13,
}

const pendingIndicator = {
  ...greenIndicator,
  background: "rgb(214, 214, 214)",
}

const redIndicator = {
  ...greenIndicator,
  background: "rgba(224, 0, 0, 1)",
}

const greenLightIndicator = {
  ...greenIndicator,
  background: "rgb(184 234 30)",
}

const MatchesSuccessIndicators = ({ submissionResults, matches }) => {
  const checkedPredictions = submissionResults?.reduce(
    (acc, curr) => acc + (curr.status !== "pending" ? 1 : 0),
    0
  )
  const submissionSuccess = submissionResults?.filter((match) => {
    return match.status === "success"
  })

  return (
    <div>
      <p className="mb-1 round text-center" style={predictionsHeaderStyle}>
        Pronósticos Correctos: {submissionSuccess?.length} de &nbsp;
        {checkedPredictions}
      </p>
      <div className="d-flex pb-3 justify-content-center">
        {submissionResults &&
          submissionResults
            .sort(
              (a, b) =>
                new Date(a.match.match_start) - new Date(b.match.match_start)
            )
            .map((singleMatch) => {
              if (
                singleMatch.status === "pending" ||
                !hasMatchEndedYet(
                  matches?.find((m) => m.id === singleMatch?.matchId)
                    ?.status_code
                )
              ) {
                return (
                  <div
                    key={singleMatch.id.toString()}
                    style={pendingIndicator}
                  ></div>
                );
              } else if (singleMatch.successPoints === 5) {
                return (
                  <div
                    key={singleMatch.id.toString()}
                    style={greenIndicator}
                  ></div>
                );
              } else if (singleMatch.successPoints === 2) {
                return (
                  <div
                    key={singleMatch.id.toString()}
                    style={greenLightIndicator}
                  ></div>
                );
              } else if (singleMatch.successPoints === 0) {
                return (
                  <div
                    key={singleMatch.id.toString()}
                    style={redIndicator}
                  ></div>
                );
              } else {
                return [];
              }
            })}
      </div>
    </div>
  );
}

export default MatchesSuccessIndicators
