import React, { useEffect, useState } from "react";
import "./DesktopHeader.css";
import GreenHowToPlay from "../images/sideNavDesktop/GreenHowToPlay.png";
import BlackHowToPlay from "../images/sideNavDesktop/HowToPlay.png";
import { Link } from "react-router-dom";
import PagesEnum from "../enums/pages";

const DesktopHeader = ({ pageName, bgColor, textColor }) => {
  const [headerText, setHeaderText] = useState("");

  useEffect(() => {
    switch (pageName) {
      case PagesEnum.parlays:
        setHeaderText("PARLAYS");
        break;
      case PagesEnum.leaderboard:
        setHeaderText("CLASIFICACIÓN");
        break;
      case PagesEnum.results:
        setHeaderText("RESULTADOS");
        break;

      default:
        break;
    }
  }, [pageName]);

  if (pageName === PagesEnum.predictions) {
    return null;
  }

  return (
    <>
      <div
        className={`text-center ${
          bgColor ? "" : "bg-white"
        } desktop-header-container`}
        style={{ backgroundColor: bgColor && bgColor }}
      >
        <div className="flex-1"></div>
        <div className="flex-1">
          <p className="desktop-header-text">{headerText}</p>
        </div>
        <div className="how-to-play-desktop flex-1">
          <Link
            to="/how-to-play"
            style={{
              color: textColor ? textColor : "#353D49",
              textDecoration: "none",
            }}
          >
            <img
              src={
                pageName === PagesEnum.welcome ? GreenHowToPlay : BlackHowToPlay
              }
              alt="question"
            />
            <br />
            <span>Cómo jugar</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default DesktopHeader;
