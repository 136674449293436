/* eslint-disable no-restricted-globals */
import "./App.css";
import "./btn.css";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { SideNavProvider } from "./contexts/SideNavContext";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { UserContext } from "./contexts/UserContext";
import { ResultsScheduleContext } from "./contexts/ResultsScheduleContext";
import { PredictionsContext } from "./contexts/PredictionsContext";
import axios from "axios";

import { CURRENT_CAMPAIGN_ENDPOINT } from "./leagues/leagues";
import TermsFooter from "./GeneralComponents/TermsFooter";
import Welcome from "./screens/welcome";
import HowToPlay from "./howToPlay/HowToPlay";
import Footer from "./Footer";
import TermsConditions from "./TermsConditions/TermsConditions";
import SpanishLeague from "./leagues/SpanishLeague";
import ForgotPassword from "./Forms/ForgotPassword";
import RenewPassword from "./Forms/RenewPassword";
import RSheader from "./ResultsSchedule/RSheader";
import RSbody from "./ResultsSchedule/RSbody";
import Account from "./Account";
import SideNav from "./SideNav";
import ResultDetails from "./ResultsSchedule/ResultDetails";
import AccumulatorMatches from "./Accumulator/AccumulatorMatches";
import Leaderboard from "./leaderboard/leaderboard";
import SideNavDesktop from "./SideNavDesktop";
import MediaQuery from "react-responsive";
import PagesEnum from "./enums/pages";
import { paramEventHandler } from "./helpers/paramEventHandler";
import { Spinner } from "react-bootstrap";
import { FacebookIcon, GmailIcon, WhatsAppIcon } from "./images/share-icons";
import { PlatformsUrl } from "./components/Share/PlatfromsUrls";

axios.defaults.headers.common["app-api-key"] =
  "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed";

const paramsArray = ["register", "login", "deposit"];

const App = ({ urlParamsInstance }) => {
  axios.defaults.headers.common["app-api-key"] =
    "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed";

  //******* For CustomShare URL component  *************//
  const Platforms = [
    {
      name: "Gmail",
      icon: GmailIcon,
      tag: PlatformsUrl.gmail,
    },
    {
      name: "Whatsapp",
      icon: WhatsAppIcon,
      tag: PlatformsUrl.whatsapp,
    },
    {
      name: "Facebook",
      icon: FacebookIcon,
      tag: PlatformsUrl.facebook,
    },
  ];
  //*********************************************/
  const history = useHistory();
  const location = useLocation();

  const { loading, leaderboardLoading } = useContext(PredictionsContext);
  const { username } = useContext(UserContext);

  const [showWelcome, setShowWelcome] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const jwt = localStorage.getItem("jwt-token");
  jwt && (axios.defaults.headers.common["jwt-token"] = jwt);

  const { spanishCurrentRoundAndSubmission, spanishLastRoundAndSubmission } =
    useContext(PredictionsContext);
  const { nextSpanishRoundSubmission, spanishPastRoundPredictions } =
    useContext(ResultsScheduleContext);

  const displayWelcome = useCallback(() => {
    setShowWelcome(
      Boolean(
        spanishCurrentRoundAndSubmission?.submission ||
          spanishLastRoundAndSubmission?.submission ||
          nextSpanishRoundSubmission?.submission ||
          spanishPastRoundPredictions?.length > 0
      )
    );
  }, [
    nextSpanishRoundSubmission?.submission,
    spanishCurrentRoundAndSubmission?.submission,
    spanishLastRoundAndSubmission?.submission,
    spanishPastRoundPredictions?.length,
  ]);

  //mobile: username=(userName)&isMobile=1"
  //web: username={userName}&temptoken={tempToken}&language={lang}

  // Control the post message & analytics logic of login/register/deposit events
  useEffect(() => {
    const check = paramEventHandler(paramsArray, username, urlParamsInstance);
    console.log(check);
  }, [urlParamsInstance, username]);

  const languageParam = urlParamsInstance.get("language");
  languageParam && sessionStorage.setItem("language", languageParam);

  const isMobileParam = urlParamsInstance.get("isMobile");
  isMobileParam && sessionStorage.setItem("isMobile", isMobileParam);

  useEffect(() => {
    setIsLoading(true);
    displayWelcome();
    setIsLoading(false);
  }, [displayWelcome]);

  if (isLoading || loading) {
    return (
      <Spinner
        className="spinner"
        animation="border"
        style={{ color: "rgba(121, 192, 0, 1)" }}
      />
    );
  }

  return (
    <SideNavProvider>
      <div className="App">
        <MediaQuery minWidth={768}>
          <SideNavDesktop platforms={Platforms} />
        </MediaQuery>
        <SideNav platforms={Platforms} />

        <Switch>
          <Route exact path={"/"}>
            {/* <Redirect to={`/welcome`} /> */}
            <Redirect
              to={
                showWelcome && username
                  ? `/${CURRENT_CAMPAIGN_ENDPOINT}`
                  : `/welcome`
              }
            />
          </Route>
          <Route exact path={"/how-to-play"}>
            <HowToPlay />
          </Route>
          <Route exact path={"/terms-conditions"}>
            <TermsConditions />
          </Route>
          <Route exact path={"/welcome"}>
            <Welcome />
          </Route>
          <Route exact path={"/forgot-password"}>
            <ForgotPassword />
          </Route>
          <Route path={"/renew-password"}>
            <RenewPassword />
          </Route>

          <Route exact path={"/account"}>
            <Account />
          </Route>

          <Route exact path={"/results"}>
            <RSbody></RSbody>
          </Route>

          <Route exact path={`/results-schedule/${CURRENT_CAMPAIGN_ENDPOINT}`}>
            <RSheader pageName={PagesEnum.results} />
            <RSbody></RSbody>
          </Route>

          <Route exact path={`/leaderboard/${CURRENT_CAMPAIGN_ENDPOINT}`}>
            <Leaderboard />
          </Route>

          <Route
            path={`/results-schedule/${CURRENT_CAMPAIGN_ENDPOINT}/:roundId`}
          >
            <ResultDetails />
          </Route>

          <Route exact path={`/${CURRENT_CAMPAIGN_ENDPOINT}/1x2`}>
            <AccumulatorMatches
              league={`${CURRENT_CAMPAIGN_ENDPOINT}`}
              accumPage={"ftResult"}
              from={"accumulator"}
              typeOfBet={"FTR"}
            />
          </Route>

          <Route exact path={`/${CURRENT_CAMPAIGN_ENDPOINT}/u-o-2.5-goals`}>
            <AccumulatorMatches
              league={`${CURRENT_CAMPAIGN_ENDPOINT}`}
              accumPage={"uo"}
              from={"accumulator"}
              typeOfBet={"OU"}
            />
          </Route>

          <Route exact path={`/${CURRENT_CAMPAIGN_ENDPOINT}/bbts`}>
            <AccumulatorMatches
              league={`${CURRENT_CAMPAIGN_ENDPOINT}`}
              accumPage={"bbts"}
              from={"accumulator"}
              typeOfBet={"BBTS"}
            />
          </Route>

          <Route exact path={`/${CURRENT_CAMPAIGN_ENDPOINT}`}>
            <SpanishLeague pageName={"PREDICTIONS"} from={"predictions"} />
          </Route>

          <Route exact path={["/"]} />
        </Switch>
        {location.pathname === "/welcome" ? null : (
          <MediaQuery maxWidth={768}>
            {sessionStorage.getItem("os") === "android" && (
              <TermsFooter
                leaderboardSpinner={leaderboardLoading}
                predictionsSpinner={loading}
              />
            )}
            <Footer />
          </MediaQuery>
        )}
      </div>
    </SideNavProvider>
  );
};

export default App;
