import React from "react"
import Nav from "../Header/Nav"
import ResultsTabs from "./ResultsTabs"
import v from "../images/results/v.png"
import x from "../images/results/x.png"
import { matchStatusEnum } from "../helpers/match-status"

const scoreBoxStyle = {
  border: "1px solid #DADADA",
  boxSizing: "border-box",
  borderRadius: 7,
  width: 32,
  height: 35,
}

const predictedScoreNumberStyle = {
  fontWeight: "bold",
  fontSize: 18,
}

const successStyle = {
  ...predictedScoreNumberStyle,
  color: "green",
}
const failureStyle = {
  ...predictedScoreNumberStyle,
  color: "red",
}

const finalScoreScoreNumberStyle = {
  fontWeight: "bold",
  fontSize: 18,
}

const predictedTextStyle = {
  fontSize: 12,
}

function ScoreBox({
  realResultHomeTeam,
  realResultAwayTeam,
  userHomePrediction,
  userAwayPrediction,
  hasMatchEnded,
  hasMatchStarted,
  statusCode
}) {

  let matchStatusDisplay; //only for live match
  switch (statusCode) {
    case matchStatusEnum.HalfTime:
    case matchStatusEnum.BreakTime:
      matchStatusDisplay = "Halftime";
      break;
    default:
      matchStatusDisplay = "Live Score";
  }

  function isCorrectPrediction() {
    if (
      userHomePrediction === realResultHomeTeam &&
      userAwayPrediction === realResultAwayTeam
    ) {
      return true
    } else {
      return false
    }
  }
  return (
    <div className="scoreBox" style={{ width: "6.5em" }}>
      {userHomePrediction || userHomePrediction === 0 ? (
        <div className="mt-3">
          <p style={predictedTextStyle} className="text-center mb-2">
            Pronóstico
          </p>
          <div className="d-flex justify-content-between mb-3">
            <div style={scoreBoxStyle}>
              <p
                style={
                  hasMatchEnded
                    ? isCorrectPrediction()
                      ? successStyle
                      : failureStyle
                    : {}
                }
                className="text-center mt-1"
              >
                {userHomePrediction}
              </p>
            </div>
            <div>
              {hasMatchEnded ? (
                <img
                  style={{ width: 15, height: 15, marginTop: 5 }}
                  src={hasMatchEnded ? (isCorrectPrediction() ? v : x) : {}}
                  alt="correct-or-wrong-prediction"
                />
              ) : (
                <></>
              )}
            </div>

            <div style={scoreBoxStyle}>
              <p
                style={
                  hasMatchEnded
                    ? isCorrectPrediction()
                      ? successStyle
                      : failureStyle
                    : {}
                }
                className="text-center mt-1"
              >
                {userAwayPrediction}
              </p>
            </div>
          </div>
        </div>
      ) : null}

      <div
        className="finalSoreBox"
        style={userHomePrediction ? null : { marginTop: 10 }}
      >
        {hasMatchEnded &&
          <p style={predictedTextStyle} className="text-center mb-2">
            Resultado Final
          </p>
        }
        {!hasMatchEnded && hasMatchStarted &&
          <p style={predictedTextStyle} className="text-center mb-2">
            {matchStatusDisplay}
          </p>
        }

        <div className="d-flex justify-content-between mb-3">
          <div style={scoreBoxStyle}>
            <p style={finalScoreScoreNumberStyle} className="text-center mt-1">
              {realResultHomeTeam}
            </p>
          </div>
          <div>
            <p style={{ fontSize: 20 }}>-</p>
          </div>
          <div style={scoreBoxStyle}>
            <p style={finalScoreScoreNumberStyle} className="text-center mt-1">
              {realResultAwayTeam}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScoreBox
