import React, { useContext, useMemo, useState } from "react";
import hamburger from "../images/hamburger.svg";
import shareIcon from "../images/header/ShareIcon.svg";
import codereLogo from "../images/header/MVPCodereForm.png";
import { Link } from "react-router-dom";
import { SideNavContext } from "../contexts/SideNavContext";
import { PredictionsContext } from "../contexts/PredictionsContext";
import ModalExit from "../Modal/ModalExit";
import { Hidden } from "@material-ui/core";
import PagesEnum from "../enums/pages";

function Nav({ banner, pageName }) {
  const { sideBar, setSidebar } = useContext(SideNavContext);
  const [show, setShow] = useState(false);
  const { isModify } = useContext(PredictionsContext);

  const isInApp = +sessionStorage.getItem("isMobile") === 1;
  
  //***********Style Objects***********//
  const navbarStyle = {
    backgroundColor: "#333F48",
    paddingTop: `env(safe-area-inset-top, 40px)`,
    justifyContent: "space-between",
  };

  let arrowStyle;

  if (!isInApp) {
    arrowStyle = {
      color: "white",
      marginRight: "0.2em",
      fontSize: "2em",
      height: 20,
    };
  }

  if (isInApp) {
    arrowStyle = {
      color: "white",
      marginRight: "0.2em",
      fontSize: "2em",
      height: 20,
      visibility: "hidden",
    };
  }
  //****************************************//

  const clickNavBar = (event) => {
    event.stopPropagation();
    if (!isModify) {
      setSidebar(!sideBar);
    } else {
      setShow(true);
    }
  };

  return (
    <div
      className={
        banner ? "nav-shadow pb-4 container-fluid pt-4" : "container-fluid pt-4"
      }
      style={
        pageName === PagesEnum.leaderboard ||
        pageName === PagesEnum.termsAndCond
          ? { backgroundColor: "#333F48", paddingBottom: 13 }
          : { backgroundColor: "#333F48" }
      }
    >
      <div style={navbarStyle}>
        <nav style={navbarStyle} className="d-flex py-0">
          <ModalExit
            ready={show}
            setReady={setShow}
            back={() => setSidebar(!sideBar)}
          />
          {isInApp && (
            <React.Fragment>
              <div>
                <div
                  style={{ marginTop: 8 }}
                  onClick={(event) => clickNavBar(event)}
                >
                  <img src={hamburger} alt="hamburger menu" />
                </div>
              </div>
              <div className="predictions mx-5 mb-0">
                <img src={codereLogo} alt="logo" />
              </div>
              <div>
                <a
                  style={{ visibility: "hidden" }}
                  href="https://www.codere.mx/yak"
                  target="_top"
                >
                  &rarr;
                </a>
              </div>
            </React.Fragment>
          )}
          {!isInApp && (
            <React.Fragment>
              <div>
                <a
                  style={arrowStyle}
                  href="https://www.codere.mx/yak"
                  target="_top"
                >
                  &larr;
                </a>
              </div>
              <div className="predictions  mx-5 mb-0">
                <img src={codereLogo} alt="logo" />
              </div>
              <div
                  style={{ marginTop: 8 }}
                  onClick={(event) => clickNavBar(event)}
                >
                  <img src={hamburger}  alt="hamburger menu" />
                </div>
           
            </React.Fragment>
          )}
        </nav>
      </div>
    </div>
  );
}

export default Nav;
