import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router";
import { PredictionsContext } from "../contexts/PredictionsContext";
import { CURRENT_CAMPAIGN_ENDPOINT } from "../leagues/leagues";
import editIcon from "../images/header/editIcon.svg";
import editIconDisabled from "../images/header/editIconDisabled.svg";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import EditBtn from "../GeneralComponents/EditBtn";
import HowToPlay from "../images/sideNavDesktop/HowToPlay.png";
import "./Round.css";

function Round({ userAddedPredictions, lastRound }) {
  const {
    english_RoundNumber_CurrentRound,
    spanish_RoundNumber_CurrentRound,
    english_RoundNumber_NextRound,
    spanish_RoundNumber_NextRound,
    spanish_RoundNumber_LastRound,
    spanish_BetAllowed_NextRound,
    editModeSpanishLeague,
    setEditModeSpanishLeague,
    spanish_Matches_NextRound,
  } = useContext(PredictionsContext);
  const location = useLocation();

  const isMobileScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  // let roundStartedAt
  // spanish_Matches_CurrentRound
  //   ? (roundStartedAt = moment(
  //       spanish_Matches_CurrentRound[0]?.match_start
  //     ).format("ddd, MMMM D LT"))
  //   : (roundStartedAt = "")
  // console.log(roundStartedAt)

  const hasRoundStarted = !!(
    location.pathname === `/${CURRENT_CAMPAIGN_ENDPOINT}` &&
    spanish_RoundNumber_CurrentRound
  );

  return (
    <div>
      <div
        className="round text-center bg-white"
        style={
          !isMobileScreen
            ? {
                fontWeight: "bold",
                paddingTop: ".5rem",
                display: "flex",
                flexDirection: "row",
              }
            : {
                fontWeight: 500,
                paddingTop: ".5rem",
                display: "flex",
                flexDirection: "row",
              }
        }
      >
        <div className=" hoz-flex-item-one"></div>
        <div className=" hoz-flex-item-two"></div>
        {isMobileScreen ? (
          <div className="hoz-flex-item-three">
            {!lastRound && (
              <EditBtn
                userAddedPredictions={userAddedPredictions}
                editMode={editModeSpanishLeague}
                setEditMode={setEditModeSpanishLeague}
              />
            )}
          </div>
        ) : (
          <div className="hoz-flex-item-three">
            <div
              style={{
                display: "block",
              }}
            >
              <Link
                style={{ color: "#353D49", textDecoration: "none" }}
                to="/how-to-play"
              >
                <img src={HowToPlay} alt="how-to-play" />
                <br />
                <span>Cómo jugar</span>
              </Link>
            </div>
          </div>
        )}
      </div>
      <div></div>
      <div
        style={{
          paddingTop: "1em",
          paddingBottom: "1em",
          display: "block",
          padding: "0 2rem",
          textAlign: "center",
        }}
      >
        {(location.pathname === `/${CURRENT_CAMPAIGN_ENDPOINT}` &&
          spanish_RoundNumber_CurrentRound) ||
          spanish_RoundNumber_NextRound ||
          spanish_RoundNumber_LastRound}
        {hasRoundStarted && (
          <>
            <span style={{ color: "#989898" }}> &nbsp; (iniciada)</span>
            <p>Los pronósticos para esta jornada ya no están disponibles</p>
          </>
        )}
        <div>
          {!hasRoundStarted && (
            <div
              className="round text-center bg-white"
              style={{ color: isMobileScreen ? "#989898" : "#79C000" }}
            >
              Acierta y llévate una Experiencia VIP con River en Buenos Aires,
              Xbox, camisetas del Real Madrid o freebets.
              {!isMobileScreen && (
                <>
                  <br /> ¡Ah! Y si logras ser el número 1 del ranking ¡Podrás
                  jugar en la Copa Codere con tus amigos!
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// Por favor crea tus 6 pronósticos
export default Round;
