import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import androidFooterLogos from "../images/mobile-footer/androidFooterLogos.png";
import "./TermsFooter.css";

export default function TermsFooter({
  predictionsSpinner,
  leaderboardSpinner,
}) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (predictionsSpinner === true || leaderboardSpinner === true) {
      setIsLoading(true);
    } else if (predictionsSpinner === false || leaderboardSpinner === false) {
      setIsLoading(false);
    }
  }, [predictionsSpinner, leaderboardSpinner]);

  console.log(predictionsSpinner, leaderboardSpinner);
  const openLink = (link, url) => {
    if (window.parent.Android != undefined) {
      window.parent.Android.postMessage(
        JSON.stringify({
          url: url,
          data: { link: link },
        })
      );
    } else {
      window.open(link, "_blank");
    }
  };
  return (
    <div style={isLoading ? { display: "none" } : termsFotterStyle}>
      <div
        style={{
          color: "#9E9E9E",
          fontSize: 12,
          cursor: "pointer",
          fontWeight: 400,
          padding: "0 30px",
        }}
      >
        <div className="terms__TermsFooter">
          <Link to={"/terms-conditions"}>TÉRMINOS Y CONDICIONES</Link>
        </div>
        Por favor, juegue de manera responsable y solo apueste lo que pueda
        pagar. Para obtener ayuda y apoyo para la adicción al juego, comuníquese
        con:
      </div>
      <div
        onClick={() =>
          openLink("http://www.juegosysorteos.gob.mx/", "terminos")
        }
      >
        <img src={androidFooterLogos} alt="img" />
      </div>
    </div>
  );
}

const termsFotterStyle = {
  padding: "10px 0",
  textAlign: "center",
  backgroundColor: "#333F48",
  marginBottom: "0.8rem",
};
