import React, { useState, useEffect, useContext } from "react";

import scoreBoxInactivePng from "../images/match-box/scoreBoxInactive.png";
import scoreBoxActivePng from "../images/match-box/scoreBoxActive.png";
import downArrowActive from "../images/match-box/downArrowActive.svg";
import downArrowInActive from "../images/match-box/downArrowInactive.png";
import upArrowActive from "../images/match-box/upArrowActive.svg";
import upArrowInactive from "../images/match-box/upArrowInactive.png";
import { PredictionsContext } from "../contexts/PredictionsContext";
import isNullOrUndefined from "./../helpers/is-null-or-undefined";

const Prediction = ({ setGoals, goals, disabled, postponed = false }) => {
  const increment = () => {
    !isNullOrUndefined(goals) ? setGoals(goals + 1) : setGoals(0);
  };

  const decrement = () => {
    goals > 0 && setGoals(goals - 1);
  };

  return (
    <div className="prediction-box">
      {!postponed && (
        <>
          <button
            disabled={disabled}
            onClick={increment}
            className="arrows-box arrow-up"
          >
            <img
              className="arrows-img mx-auto d-block"
              src={disabled ? upArrowInactive : upArrowActive}
              alt="button"
            />
          </button>
          <div
            className="prediction-num-wrapper"
            style={{ backgroundColor: disabled ? "#F6F6F6" : "inherit" }}
          >
            <div className="prediction-num">{goals}</div>
          </div>
          <button
            disabled={disabled || !goals}
            onClick={decrement}
            className="arrows-box arrow-down"
          >
            <img
              className="arrows-img mx-auto d-block"
              src={disabled || !goals ? downArrowInActive : downArrowActive}
              alt="button"
            />
          </button>
        </>
      )}
    </div>
  );
};

export default Prediction;
