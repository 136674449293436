import React from "react"

function BBTS() {
  return (
    <div className="container">
      <div className="container accum-matches-box-headers">
        <div className="row mb-2">
          <div className="col-5"></div>
          <div className="col text-center">Si</div>
          <div className="col text-center">No</div>
        </div>
      </div>
    </div>
  )
}

export default BBTS
