import React from "react";
import Time from "./Time";
import Team from "./Team";
import Prediction from "./Prediction";

function Teams(props) {
  return <div>{props.children}</div>;
}

export default Teams;
