import { Fade, TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import axios from "axios";
import { CancelToken } from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { BASE_URL } from "../environment";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "calc(100% - 1.6rem)",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    borderRadius: "15px",
    padding: "1.4rem",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    fontFamily: "sans-serif",
    [theme.breakpoints.up("md")]: {
      // if you want to set the md size value
      width: "calc(50% - 1.6rem)",
    },
  },
  hl: {
    fontSize: "1.6rem",
    color: "#79C000",
    fontWeight: "600",
    marginBottom: ".6rem",
  },
  desc: {
    marginBottom: "1.4rem",
  },
  notice: {
    color: "rgba(53, 61, 73, 0.6)",
    fontSize: ".9rem",
    lineHeight: "1.2rem",
    marginBottom: ".6rem",
  },
  submitBtn: {
    backgroundColor: "#353D49",
    border: "none",
    color: "white",
    borderRadius: "20px",
    margin: "0 auto",
    display: "block",
    width: "15.6rem",
    height: "3rem",
    maxWidth: "calc(100% - 1rem)",
    ["&:disabled"]: {
      backgroundColor: "rgba(53, 61, 73, 0.3)",
    },
  },
  helperText: {
    marginBottom: "1.7rem",
    color: "#79C000",
  },
  errorText: {
    marginBottom: "1.7rem",
    color: "#E00000",
  },
  helperIcon: {
    borderRadius: "50%",
    backgroundColor: "#79C000",
    color: "white",
    marginRight: ".6rem",
    height: "1.5rem",
    width: "1.5rem",
    padding: ".1rem",
  },
  errorIcon: {
    borderRadius: "50%",
    backgroundColor: "#E00000",
    color: "white",
    marginRight: ".6rem",
    height: "1.5rem",
    width: "1.5rem",
    padding: ".1rem",
  },
  exitBtn: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    height: "1rem",
    width: "1rem",
    backgroundColor: "transparent",
    color: "rgba(53, 61, 73, 0.6)",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const NicknameModal = ({ onCloseModal, isOpen }) => {
  const [nickname, setNickname] = useState("");
  const [error, setError] = useState(null);
  const [nicknameCheckLoading, setNicknameCheckLoading] = useState(false);
  const [isNicknameValid, setIsNicknameValid] = useState(null);
  const [loadingNicknameSave, setLoadingNicknameSave] = useState(false);
  const classes = useStyles();
  const timeoutRef = useRef(null);
  const reqCancelRef = useRef(CancelToken.source());
  const { setHasNickname, askUserForNicknameAttemptsLeft } =
    useContext(UserContext);

  const handleCloseModal = (hasUserSelectedNickname) => {
    onCloseModal();
    setNicknameCheckLoading(false);
    setLoadingNicknameSave(true);
    cancelLastNicknameCheck();
    let userSelectedNickname;
    if (nickname?.length && hasUserSelectedNickname) {
      hasUserSelectedNickname = true;
      userSelectedNickname = nickname;
    }
    axios
      .post(`${BASE_URL}/api/set-nickname-to-user`, {
        hasNickname: hasUserSelectedNickname,
        nickname: userSelectedNickname,
      })
      .then((res) => {
        setHasNickname(hasUserSelectedNickname);
      })
      .catch((err) => {
        console.error(err);
        setError("Error");
      });
  };

  const cancelLastNicknameCheck = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (reqCancelRef.current?.cancel) {
      reqCancelRef.current.cancel();
      reqCancelRef.current = CancelToken.source();
    }
  };

  useEffect(() => {
    setNicknameCheckLoading(false);
    if (!nickname || error === "Invalid nickname") return;
    cancelLastNicknameCheck();
    timeoutRef.current = setTimeout(async () => {
      try {
        setNicknameCheckLoading(true);
        const {
          data: { isNicknameUnique },
        } = await axios.get(
          `${BASE_URL}/api/check-nickname-unique?nickname=${nickname}`,
          { cancelToken: reqCancelRef.current.token }
        );
        setNicknameCheckLoading(false);
        setIsNicknameValid(isNicknameUnique);
        if (isNicknameUnique === false) {
          setError("Este alias ya existe");
        }
      } catch (error) {
        console.error(error);
      }
    }, 500);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [nickname]);

  const body = (
    <Fade in={isOpen}>
      <div className={classes.paper}>
        <button
          onClick={() => {
            handleCloseModal(false);
          }}
          className={classes.exitBtn}
        >
          <CloseIcon />
        </button>
        <div className={classes.hl}>Elige Alias</div>
        <div className={classes.desc}>
          Introduce tu alias para que otros jugadores puedan verlo en la
          Clasificación
        </div>
        <TextField
          disabled={loadingNicknameSave}
          variant="outlined"
          fullWidth
          className="inputRounded"
          error={!!error}
          value={nickname}
          onChange={(ev) => {
            setNickname(ev.target.value);
            setIsNicknameValid(null);
            !new RegExp(/^[A-Za-z][A-Za-z0-9]*$/).test(ev.target.value)
              ? setError("Alias inválido")
              : setError(null);
          }}
          type="text"
        />
        <div style={{ height: "5rem", paddingTop: ".6rem" }}>
          {nicknameCheckLoading && (
            <CircularProgress size={"1.5rem"} style={{ color: "#353D49" }} />
          )}
          <div className={!!error ? classes.errorText : classes.helperText}>
            {!!nickname ? (
              <span>
                {!!error ? (
                  <>
                    <PriorityHighIcon className={classes.errorIcon} />
                    {error}
                  </>
                ) : nicknameCheckLoading || isNicknameValid === null ? (
                  <></>
                ) : (
                  <>
                    <CheckIcon className={classes.helperIcon} />
                    Alias disponible
                  </>
                )}
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={classes.notice}>
          <span>
            * Si no seleccionas un alias, no podrás ser parte de la
            clasificación
          </span>
        </div>
        <div>
          <button
            disabled={
              loadingNicknameSave || !nickname || !isNicknameValid || error
            }
            className={classes.submitBtn}
            onClick={() => {
              if (!nickname || error === "Invalid nickname") return;
              handleCloseModal(true);
            }}
          >
            OK
          </button>
        </div>
      </div>
    </Fade>
  );

  return (
    <Modal
      open={isOpen}
      onClose={() => handleCloseModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};
export default NicknameModal;
