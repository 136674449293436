import { Grid, Switch, TextField } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { BASE_URL } from "../environment";
import codere_signup_logo from "../images/signup/codere_signup_logo.svg";
import arrowLeft from "../images/mobile-footer/arrowLeft.png";
import emailSentImg from "../images/forgotPassword/emailSent.svg";
import vi from "../images/forgotPassword/vi.png";

import VipLogo from "../Header/VipFormHeader";
// page styling

const mainGridStyle = {
  padding: 30,
  // minHeight: "vh",
  margin: "20px auto",
};
const inputGrid = {
  marginTop: 50,
};

const mainLogo = {
  display: "block",
  width: 174,
  marginLeft: "auto",
  marginRight: "auto",
  paddingTop: 60,
  paddingBottom: 60,
};

const emailSentStyle = {
  ...mainLogo,
  width: 106,
};

const emailSentFirstText = {
  fonSize: "1rem",
};

const logInStyle = {
  fontWeight: "bold",
  fontSize: 30,
};

const textFieldStyle = {
  marginBottom: 20,
};

const footerStyle = {
  backgroundColor: "#353D49",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  paddingBottom: 250,
  marginTop: 70,
  bottom: 0,
};

// end of page styling
function ForgotPassword() {
  const [inputData, setInputData] = useState({
    email: "",
  });
  const [error, setError] = useState({
    email: false,
  });

  let btnClass = "button email-recover-btn-inactive";

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [emailSent, setEmailSent] = useState(false);

  if (btnDisabled === false) {
    btnClass = "button email-recover-btn-active";
  } else if (btnDisabled === true) {
    btnClass = "button email-recover-btn-inactive";
  }

  const history = useHistory();

  let emailHelperText = "";
  if (error.email) {
    emailHelperText = "Please enter a valid email.";
  }

  useEffect(() => {
    //email validation
    const emailRegEx = new RegExp(
      /^(?!.*\.\.)[\w.\-#!$%&'*+\/=?^_`{}|~]{1,35}@[\w.\-]+\.[a-zA-Z]{2,15}$/
    ).test(inputData.email);

    if (emailRegEx || inputData.email === "") {
      setError((prevState) => {
        return {
          ...prevState,
          email: false,
        };
      });
    } else if (!emailRegEx) {
      setError((prevState) => {
        return {
          ...prevState,
          email: true,
        };
      });
    }

    if (!emailRegEx || inputData.email === "") {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [inputData]);

  function handleChange(event) {
    //changing every field state
    const { name, value } = event.target;
    setInputData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const [blankError, setBlankError] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    if (inputData.email === "") {
      setBlankError("Please do not leave an empty field.");
    } else {
      sendForgotPassPostReq();
    }
  }

  function sendForgotPassPostReq() {
    axios
      .get(
        `${BASE_URL}/auth/reset-password-request?email=${inputData.email}`,

        { headers: { "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed" } }
      )
      .then(function (response) {
        if (response.status === 200) {
          //console.log(response);
          //   window.location.href = response.data.urlForRedirect;
          setEmailSent(true);
        }
      })
      .catch(function (error) {
        //console.log(error);
        setBlankError("Email don't exist");
      });
  }

  return (
    <div className="container-fluid p-0" style={{ position: "relative" }}>
      <VipLogo></VipLogo>
      <Grid style={mainGridStyle}>
        <h1 style={logInStyle}>Forgot Password?</h1>

        {emailSent ? (
          <div>
            <p>
              An email has been sent to your rescue email address,&nbsp;
              {inputData.email}
            </p>
            <p style={{ color: "#C2C2C2" }}>
              Please, check your mailbox and click the link in the body message.
            </p>
            <img style={emailSentStyle} src={emailSentImg} alt="" />
          </div>
        ) : (
          <div>
            <p style={emailSentFirstText}>
              Enter email address associated with your account.
              {inputData.email}
            </p>
            <Grid style={inputGrid}>
              <form onSubmit={handleSubmit}>
                <div className="text-field-div">
                  <TextField
                    error={error.email}
                    helperText={emailHelperText}
                    variant="outlined"
                    style={textFieldStyle}
                    fullWidth
                    label="Email Address"
                    value={inputData.email}
                    name="email"
                    onChange={handleChange}
                  ></TextField>
                </div>
              </form>
              <div>
                <p style={{ color: "red" }}>{blankError}</p>
              </div>

              <button
                disabled={btnDisabled}
                onClick={handleSubmit}
                className={btnClass}
              >
                Email me a recovery link
              </button>
            </Grid>
          </div>
        )}
      </Grid>
      {emailSent ? (
        <div style={footerStyle} className="">
          <Link className="col-12  forgot-password-footer-text" to="/login">
            <img className="vi-email-sent" src={vi} alt="vi" />
            <span className="email-sent-text">DONE</span>
          </Link>
        </div>
      ) : (
        <div style={footerStyle} className="">
          <Link className="col-12  forgot-password-footer-text" to="/login">
            <img src={arrowLeft} alt="" />
            <span className="back-to-login-text">BACK TO LOG IN</span>
          </Link>
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
