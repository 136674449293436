import React from "react";

function CostumButton({ children, inTheMiddleOfThePage, className = '' }) {
  if (inTheMiddleOfThePage) {
    return <div className={`"button-no-margin-bottom" ${className}`}>{children}</div>;
  } else {
    return <div className={`"button-wrapper" ${className}`}>{children}</div>;
  }
}

export default CostumButton;
