import axios from "axios";
import { createContext, useEffect, useRef, useState } from "react";
import { BASE_URL } from "../environment";
import isNullOrUndefined from "../helpers/is-null-or-undefined";
import { CURRENT_CAMPAIGN_ENDPOINT } from "../leagues/leagues";
import { Spinner } from "react-bootstrap";

export const UserContext = createContext(null);

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [hasNickname, setHasNickname] = useState(null);
  const [checkedNickname, setCheckedNickname] = useState(false);
  const [username, setUsername] = useState(null);
  const [loading, setLoading] = useState(true);

  const askUserForNicknameAttemptsLeft = useRef(1);

  useEffect(() => {
    (async () => {
      let jwt = localStorage.getItem("jwt-token");
      if (jwt === "null") jwt = null;

      const paramsJwt = sessionStorage.getItem("jwt-token");

      if (paramsJwt) {
        if (jwt !== paramsJwt) {
          jwt = paramsJwt;
          localStorage.setItem("jwt-token", jwt);
        }
        sessionStorage.removeItem("jwt-token");
      }

      if (jwt) {
        setUser(true);
        jwt && (axios.defaults.headers.common["jwt-token"] = jwt);
      } else {
        setUser(false);
        axios.defaults.headers.common["jwt-token"] = "";
      }
      setLoading(false);
    })();
  }, [CURRENT_CAMPAIGN_ENDPOINT, setUser, username]);

  useEffect(() => {
    user &&
      axios
        .get(`${BASE_URL}/auth/profile`)
        .then((res) => {
          const fetchedUsername = res.data?.user;
          if (!isNullOrUndefined(fetchedUsername)) {
            setUsername(fetchedUsername);
          }
          const fetchedNickname = res.data?.user?.nickname;
          if (!isNullOrUndefined(fetchedNickname)) {
            setHasNickname(true);
          }
          setCheckedNickname(true);
        })
        .catch((err) => console.error({ "/auth/profile": err }));
  }, [setHasNickname, user, setUsername]);

  return !loading ? (
    <UserContext.Provider
      value={{
        user,
        username,
        setUser,
        hasNickname,
        setHasNickname,
        checkedNickname,
        askUserForNicknameAttemptsLeft,
      }}
    >
      {children}
    </UserContext.Provider>
  ) : (
    <Spinner />
  );
}
