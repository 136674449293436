import { createContext, useState } from "react"

export const SideNavContext = createContext()

export function SideNavProvider({ children }) {
  const [sideBar, setSidebar] = useState(false)

  return (
    <SideNavContext.Provider value={{ sideBar, setSidebar }}>
      {children}
    </SideNavContext.Provider>
  )
}
