import React, { useEffect } from "react";
import PropTypes from "prop-types";
// import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { CURRENT_CAMPAIGN_ENDPOINT } from "../leagues/leagues";

const useStyles = makeStyles((theme) => ({
  customTwo: {
    fonWeight: 1000,
    fontSize: 12,
  },
}));

export default function LeaderboardTabs({ onTabChange }) {
  const location = useLocation();

  const [selectedTab, setTabSelected] = React.useState(0);

  // fix of tab indicator bug
  // useEffect(() => {
  //   if (location.pathname === `/leaderboard/${CURRENT_LEAGUE_ENDPOINT_2}`) {
  //     setTabSelected(1);
  //   }
  // }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setTabSelected(newValue);
    onTabChange();
  };

  const handleChangeIndex = (index) => {
    setTabSelected(index);
  };

  const routes = [CURRENT_CAMPAIGN_ENDPOINT];
  const campaignToUpper = CURRENT_CAMPAIGN_ENDPOINT.toLocaleUpperCase();

  return (
    <div
      style={{ marginTop: "2em" }}
      className="container-fluid p-0 tabs-container-light leaderboard-specific"
    >
      <div color="default">
        <Tabs
          TabIndicatorProps={{
            style: { height: 3, background: "rgba(121, 192, 0, 1)" },
          }}
          value={selectedTab}
          onChange={handleChange}
          textColor="primary"
          variant="fullWidth"
          aria-label="tabs"
        >
          <Tab
            label={`${campaignToUpper}`}
            component={Link}
            to={routes[0]}
            replace
          />
          {/* <Tab label={`${league2ToUpper}`} component={Link} to={routes[1]} replace /> */}
        </Tabs>
      </div>
    </div>
  );
}
