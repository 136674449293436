import React, { useContext, useEffect, useState } from "react";
import AccumulatorSingleMatchFTResult from "./AccumulatorSingleMatchFTResult";
import AccumulatorSingleMatchOU from "./AccumulatorSingleMatchOU";
import AccumulatorSingleMatchBBTS from "./AccumulatorSingleMatchBBTS";
import CostumButton from "../GeneralComponents/Button";
import HeaderFTResult from "./HeaderFTResult";
import HeaderOU from "./HeaderOU";
import HeaderBBTS from "./HeaderBBTS";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { BASE_URL } from "../environment";
import Header from "../Header/Header";
import { useLocation } from "react-router-dom";
import { isAndroid, isBrowser, isIOS, isMobile } from "react-device-detect";
import { AccumulatorContext } from "../contexts/AccumulatorContext";
import { PredictionsContext } from "../contexts/PredictionsContext";
import { useMediaQuery } from "react-responsive";
import { hasMatchStartedYetByCode } from "../helpers/match-status";
import moment from "moment";
import TermsFooter from "../GeneralComponents/TermsFooter";
import BannerSizeEnum from "../enums/banners-size";
import PagesEnum from "../enums/pages";

function AccumulatorMatches({ league, accumPage, typeOfBet }) {
  const { banners, accumulator, loading } = useContext(AccumulatorContext);
  const { spanish_Matches_LastRound } = useContext(PredictionsContext);

  //setting state for an array of 6 positions
  const [FTRbet, setFTRbet] = useState([]);
  const [OUbet, setOUbet] = useState([]);
  const [BBTSbet, setBBTSbet] = useState([]);

  const [FTRodds, setFTRodds] = useState(null);
  const [OUodds, setOUodds] = useState(null);
  const [BBTSodds, setBBTSodds] = useState(null);
  const [factor, setFactor] = useState(null);

  const [banner, setBanner] = useState(null);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const isXsmall = useMediaQuery({ query: "(max-width: 599px)" });
  const isSmall = useMediaQuery({ query: "(min-width: 600px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const isLarge = useMediaQuery({ query: "(min-width: 1024px)" });
  const isXlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  useEffect(() => {
    if (banners) {
      if (isXsmall) {
        setBanner(
          banners.find((item) => item.size === BannerSizeEnum.xs).img_url
        );
      }
      if (isSmall) {
        setBanner(
          banners.find((item) => item.size === BannerSizeEnum.s).img_url
        );
      }
      if (isTablet) {
        setBanner(
          banners.find((item) => item.size === BannerSizeEnum.m).img_url
        );
      }
      if (isLarge) {
        setBanner(
          banners.find((item) => item.size === BannerSizeEnum.l).img_url
        );
      }
      if (isXlarge) {
        setBanner(
          banners.find((item) => item.size === BannerSizeEnum.xl).img_url
        );
      }
    }
  }, [banners, isLarge, isSmall, isTablet, isXlarge, isXsmall]);

  // creating initial array for ftrResults
  const market_types = {
    FTR: 1,
    OU: 2,
    BBTS: 3,
  };

  function getSelectedOutcomeIds(matches, market_type) {
    const outcomes = matches
      .sort((a, b) => new Date(a.match_start) - new Date(b.match_start))
      .map((match) => {
        return match.markets
          .find((market) => market.marketType === market_type)
          ?.possibleOutcomes.find((outcome) => outcome.isSelected === true);
      });

    const ids = outcomes.map((outcome) => {
      return outcome?.id;
    });
    return ids;
  }

  const selectedTrueFTRs = React.useMemo(() => {
    if (accumulator?.roundMatches)
      return getSelectedOutcomeIds(accumulator?.roundMatches, market_types.FTR);
    else return [];
  }, [accumulator?.roundMatches, market_types.FTR]);

  useEffect(() => {
    setFTRbet(selectedTrueFTRs);
  }, []);

  // creating initial array for OU
  const selectedTrueOUs = React.useMemo(() => {
    if (accumulator?.roundMatches)
      return getSelectedOutcomeIds(accumulator?.roundMatches, market_types.OU);
    else return [];
  }, [accumulator?.roundMatches, market_types.OU]);

  useEffect(() => {
    setOUbet(selectedTrueOUs);
  }, []);

  //creating initial array for BBTS
  const selectedTrueBBTSs = React.useMemo(() => {
    if (accumulator?.roundMatches)
      return getSelectedOutcomeIds(
        accumulator?.roundMatches,
        market_types.BBTS
      );
    else return [];
  }, [accumulator?.roundMatches, market_types.BBTS]);

  useEffect(() => {
    setBBTSbet(selectedTrueBBTSs);
  }, []);
  //setSpinner

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(true);
  //   }, 2000);
  // }, []);

  //if btn is true - add the current id to the right array index position.
  // if btn is false, set newArr to the current state, set the index position of the falsy btn as undefined.
  const handleFt = (index, betId, btn) => {
    if (btn) {
      const newArr = [...FTRbet];
      newArr[index] = betId;
      setFTRbet(newArr);
    } else if (!btn) {
      const newArr = [...FTRbet];
      newArr[index] = undefined;
      setFTRbet(newArr);
    }
  };

  const handleOU = (index, winId, btn) => {
    if (btn) {
      const newArr = [...OUbet];
      newArr[index] = winId;
      setOUbet(newArr);
    } else if (!btn) {
      const newArr = [...OUbet];
      newArr[index] = undefined;
      setOUbet(newArr);
    }
  };

  const handleBBTS = (index, winId, btn) => {
    if (btn) {
      const newArr = [...BBTSbet];
      newArr[index] = winId;
      setBBTSbet(newArr);
    } else if (!btn) {
      const newArr = [...BBTSbet];
      newArr[index] = undefined;
      setBBTSbet(newArr);
    }
  };

  //getting factor when component first loads
  useEffect(() => {
    function getSelectedOdds(matches, market_type) {
      const outcomes = matches.map((match) => {
        return match?.markets
          ?.find((market) => market?.marketType === market_type)
          ?.possibleOutcomes?.find((outcome) => outcome?.isSelected === true);
      });

      const odds = outcomes?.map((outcome) => outcome?.odds);
      return odds;
    }
    const selectedTrueFTRsForFactor = accumulator?.roundMatches
      ? getSelectedOdds(accumulator?.roundMatches, market_types.FTR)
      : [];

    const selectedTrueOUsForFactor = accumulator?.roundMatches
      ? getSelectedOdds(accumulator?.roundMatches, market_types.OU)
      : [];

    const selectedTrueBBTSsForFactor = accumulator?.roundMatches
      ? getSelectedOdds(accumulator?.roundMatches, market_types.BBTS)
      : [];

    setFTRodds(selectedTrueFTRsForFactor);
    setOUodds(selectedTrueOUsForFactor);
    setBBTSodds(selectedTrueBBTSsForFactor);
  }, [accumulator, market_types.BBTS, market_types.FTR, market_types.OU]);

  //getting factor after bet change
  useEffect(() => {
    if (FTRodds?.length) {
      function getSelectedOddsUpdate(market_type, betState) {
        const matches = accumulator?.roundMatches;
        const outcomes = matches?.map((match, index) => {
          return match.markets
            ?.find((market) => market?.marketType === market_type)
            ?.possibleOutcomes?.find(
              (outcome) => outcome?.id === betState[index]
            );
        });
        // const odds = outcomes.find((outcome) => outcome.odds) ? odds : outcomes;
        const odds = outcomes?.map((outcome) => {
          return outcome?.odds;
        });
        return odds;
      }

      const selectedTrueFTRsForFactor = accumulator?.roundMatches
        ? getSelectedOddsUpdate(market_types.FTR, FTRbet)
        : [];
      const selectedTrueOUsForFactor = accumulator?.roundMatches
        ? getSelectedOddsUpdate(market_types.OU, OUbet)
        : [];
      const selectedTrueBBTSsForFactor = accumulator?.roundMatches
        ? getSelectedOddsUpdate(market_types.BBTS, BBTSbet)
        : [];

      setFTRodds(selectedTrueFTRsForFactor);
      setOUodds(selectedTrueOUsForFactor);
      setBBTSodds(selectedTrueBBTSsForFactor);
    }
  }, [
    FTRbet,
    OUbet,
    BBTSbet,
    FTRodds?.length,
    accumulator?.roundMatches,
    market_types.FTR,
    market_types.OU,
    market_types.BBTS,
  ]);

  function getFactorOdds(typeOfBet) {
    let factorOdds = [];
    if (typeOfBet === "FTR" && FTRodds) {
      factorOdds = FTRodds;
    } else if (typeOfBet === "OU" && OUodds) {
      factorOdds = OUodds;
    } else {
      factorOdds = BBTSodds;
    }

    if (factorOdds) {
      let factor = 1;
      for (const outcome of factorOdds) {
        if (!outcome || isNaN(outcome)) {
          return;
        } else {
          factor *= +outcome;
        }
      }
      let num = Number(factor); // The Number() only visualizes the type and is not needed
      let roundedString = num.toFixed(2);
      let roundedFactor = Number(roundedString);

      return roundedFactor;
    }
  }
  const getFactor = getFactorOdds(typeOfBet);

  useEffect(() => {
    setFactor(getFactor);
  }, [FTRodds, getFactor]);

  //sending the array to server
  const location = useLocation();

  const market = location.pathname.split("/")[2];
  let outcomesToSend;

  switch (market.trim().toLowerCase()) {
    case "1x2":
      outcomesToSend = [...FTRbet].filter((o) => !!o);
      break;
    case "u-o-2.5-goals":
      outcomesToSend = [...OUbet].filter((o) => !!o);
      break;
    case "bbts":
      outcomesToSend = [...BBTSbet].filter((o) => !!o);
      break;
    default:
  }

  const sendPostReq = () => {
    console.log(outcomesToSend);

    axios
      .post(
        `${BASE_URL}/api/claim-free-bet-co`,
        {
          outcomes: outcomesToSend,
        },
        { headers: { "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed" } }
      )
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          if (isIOS) {
            // window.open(response.data.urlForRedirect, "_blank");
            window.location.assign(
              `${response.data.urlForRedirect}?partner=mvp`
            );
            window.top.location.href = `${response.data.urlForRedirect}?partner=mvp`;
            window.parent.location = `${response.data.urlForRedirect}?partner=mvp`;

            window.webkit.messageHandlers.observer.postMessage(
              JSON.stringify(response)
            );
            if (isAndroid) {
              document.location.href = `${response.data.urlForRedirect}?partner=mvp`;
            }
          } else {
            console.log(`${response.data.urlForRedirect}?partner=mvp`);
            window.location.assign(
              `${response.data.urlForRedirect}?partner=mvp`
            );
            window.top.location.href = `${response.data.urlForRedirect}?partner=mvp`;
            window.parent.location = `${response.data.urlForRedirect}?partner=mvp`;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let marginClass = "";
  if (banner) {
    marginClass = "container accum-dollar-bet";
  } else if (!banner) {
    marginClass = "container accum-dollar-bet-no-banner";
  }

  const relevantMatches = accumulator?.roundMatches?.filter((m) =>
    moment(m.match_start).isAfter(moment())
  );

  if (!loading) {
    if (spanish_Matches_LastRound && !accumulator?.roundMatches?.length) {
      return (
        <>
          <Header
            league={league}
            banner={banner}
            accumulatorHeader={true}
            pageName={PagesEnum.parlays}
          ></Header>

          <div style={{ marginTop: "20em" }} className="container text-center">
            <h3>
              El parlay volverá a estar disponible hasta que estén abiertos los
              pronósticos para la siguiente jornada.
            </h3>
          </div>
        </>
      );
    }
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <Header
        league={league}
        banner={banner}
        pageName={PagesEnum.parlays}
      ></Header>
      {/* spinner conditional */}
      {!loading ? (
        <div
          style={
            isDesktopOrLaptop
              ? {
                  width: 700,
                  marginLeft: "auto",
                  marginRight: "auto",
                  backgroundColor: "white",
                  paddingBottom: "8em",
                }
              : { paddingBottom: "10em" }
          }
        >
          <div
            style={{
              paddingTop: `env(safe-area-inset-top, 200px)`,
            }}
            className={marginClass}
          >
            {/* <p style={{ color: "rgba(152, 152, 152, 1)" }}>Potential gain:</p> */}
            {/* <p>
              Accumulator @
              <span className="green-text">{factor ? factor : 0}</span>
            </p> */}
            <div style={{ paddingTop: 20, backgroundColor: "white" }}>
              <p>Apostando $1000</p>
            </div>
            <p className="green-text">
              Ganas ${factor ? Math.floor(factor * 200 * 1.07) : 0}
            </p>
            <CostumButton inTheMiddleOfThePage={true}>
              <button
                id="accumulator-btn"
                onClick={sendPostReq}
                className={
                  "button my-4" +
                  (!outcomesToSend?.length ? " button-disabled" : "")
                }
                disabled={+outcomesToSend?.length < 1}
              >
                Apuesta
              </button>
            </CostumButton>
          </div>
          {accumPage === "ftResult" ? (
            <HeaderFTResult />
          ) : accumPage === "uo" ? (
            <HeaderOU />
          ) : (
            <HeaderBBTS />
          )}

          <div className="accum-matches-box container border-top px-0">
            {relevantMatches
              ?.filter(
                (item) =>
                  !item.markets.some((a) => a.possibleOutcomes.length <= 0)
              )
              .sort((a, b) => new Date(a.match_start) - new Date(b.match_start))
              .map((match, index) => (
                <div key={match?.id} className="container accum-one-match">
                  {accumPage === "ftResult" ? (
                    <AccumulatorSingleMatchFTResult
                      updatedFtrArr={FTRbet[index]}
                      homeTeamShirt={match.homeTeam.shirt_img}
                      awayTeamShirt={match.awayTeam.shirt_img}
                      homeTeamName={match.homeTeam.name}
                      awayTeamName={match.awayTeam.name}
                      markets={match?.markets}
                      handleFt={handleFt}
                      index={index}
                    />
                  ) : accumPage === "uo" ? (
                    <AccumulatorSingleMatchOU
                      updatedFtrArr={OUbet[index]}
                      homeTeamShirt={match.homeTeam.shirt_img}
                      awayTeamShirt={match.awayTeam.shirt_img}
                      homeTeamName={match.homeTeam.name}
                      awayTeamName={match.awayTeam.name}
                      markets={match?.markets}
                      handleOU={handleOU}
                      index={index}
                    />
                  ) : accumPage === "bbts" ? (
                    <AccumulatorSingleMatchBBTS
                      updatedFtrArr={BBTSbet[index]}
                      homeTeamShirt={match.homeTeam.shirt_img}
                      awayTeamShirt={match.awayTeam.shirt_img}
                      homeTeamName={match.homeTeam.name}
                      awayTeamName={match.awayTeam.name}
                      markets={match?.markets}
                      handleBBTS={handleBBTS}
                      index={index}
                    />
                  ) : null}
                </div>
              ))}
            <div className="container">
              <button
                id="accumulator-btn"
                onClick={sendPostReq}
                className={
                  "button my-4" +
                  (!outcomesToSend?.length ? " button-disabled" : "")
                }
                disabled={!outcomesToSend?.length}
              >
                Apuesta
              </button>
            </div>
            {/* <TermsFooter isDesktop={isDesktopOrLaptop} view="accumulator" /> */}
          </div>
        </div>
      ) : (
        <div>
          <Spinner
            className="spinner"
            animation="border"
            style={{ color: "rgba(121, 192, 0, 1)" }}
          />
        </div>
      )}
    </div>
  );
}

export default AccumulatorMatches;
