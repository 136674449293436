import React from "react"

function AccumulatorBoxHeaderFTResult() {
  return (
    <div className="container">
      <div className="container accum-matches-box-headers">
        <div className="row mb-2">
          <div className="col-5"></div>
          <div className="col-2 small-text text-center">1</div>
          <div className="col-3 small-text text-center">X</div>
          <div className="col-1 small-text text-center">2</div>
        </div>
      </div>
    </div>
  )
}

export default AccumulatorBoxHeaderFTResult
