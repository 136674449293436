// /* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
import React, { useState, useContext, useEffect, useCallback } from "react";
import { PredictionsContext } from "../contexts/PredictionsContext";
import { ResultsScheduleContext } from "../contexts/ResultsScheduleContext";
import Match from "./Match";
import CostumButton from "../GeneralComponents/Button";
import axios from "axios";
import { BASE_URL, LOGIN_URL_PROD } from "../environment";
import Header from "../Header/Header";
import { Spinner } from "react-bootstrap";
import ModalSubmit from "../Modal/ModalSubmit";
import isNullOrUndefined from "./../helpers/is-null-or-undefined";
import moment from "moment";
import MatchesSuccessIndicators from "../GeneralComponents/MatchesSuccessIndicators";
import localforage from "localforage";
import "moment/locale/es";
import NicknameModal from "../Modal/NicknameModal";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import EditBtn from "../GeneralComponents/EditBtn";
import { UserContext } from "../contexts/UserContext";
import { useMediaQuery } from "react-responsive";
import BannerSizeEnum from "../enums/banners-size";
import PagesEnum from "../enums/pages";
import { useDebounce } from "../hooks/useDebounce";
// import ReactGA from "react-ga";

moment.updateLocale("es", {
  weekdaysShort: "dom_lun_mar_mié_jue_vie_sáb".split("_"),
});

const Matches = ({
  userAddedPredictions,
  campaign,
  matches,
  roundId,
  currentRound,
  nextRound,
  editMode,
  setEditMode,
  savedPredictions,
  nextRoundNumber,
  spanishEntriesFrom,
  lastRound = false,
}) => {
  const { apiCallWasMade, setApiCallWasMade, setModalActive, setIsDataOld } =
    useContext(ResultsScheduleContext);

  // useEffect(() => {
  //   const entriesFrom = moment(spanishEntriesFrom).format("ddd, D MMMM YYYY");
  // }, [spanishEntriesFrom]);

  const isXsmall = useMediaQuery({ query: "(max-width: 599px)" });
  const isSmall = useMediaQuery({ query: "(min-width: 600px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const isLarge = useMediaQuery({ query: "(min-width: 1024px)" });
  const isXlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const {
    loading,
    setPrediction,
    setCampaign,
    predictionArray,
    isAfterLocalForage,
    setIsAfterLocalForage,
    setSavedPredictions,
    spanishCurrentRoundAndSubmission,
    banners,
  } = useContext(PredictionsContext);

  const { user, hasNickname, checkedNickname, askUserForNicknameAttemptsLeft } =
    useContext(UserContext);

  const [errorMessage, setErrorMessage] = useState("");
  const [postOrPut, setPostOrPut] = useState("post");
  const [renderBtn, setRenderBtn] = useState(false);
  const [accum1x2, setAccum1x2] = useState(accum1x2);
  const location = useLocation();
  const history = useHistory();
  const [entriesFrom, setEntriesFrom] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [newUserBanner, setNewUserBanner] = useState(null);
  const [currentUserBanner, setCurrentUserBanner] = useState(null);
  //show/hide Predictions-Modal
  const [showNicknameModal, setShowNicknameModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (banners) {
      if (isXsmall) {
        setNewUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.xs &&
              item.img_url.includes("NewUser")
          ).img_url
        );
        setCurrentUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.xs &&
              item.img_url.includes("CurrentUser")
          ).img_url
        );
      }
      if (isSmall) {
        setNewUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.s && item.img_url.includes("NewUser")
          ).img_url
        );
        setCurrentUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.s &&
              item.img_url.includes("CurrentUser")
          ).img_url
        );
      }
      if (isTablet) {
        setNewUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.m && item.img_url.includes("NewUser")
          ).img_url
        );
        setCurrentUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.m &&
              item.img_url.includes("CurrentUser")
          ).img_url
        );
      }
      if (isLarge) {
        setNewUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.l && item.img_url.includes("NewUser")
          ).img_url
        );
        setCurrentUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.l &&
              item.img_url.includes("CurrentUser")
          ).img_url
        );
      }
      if (isXlarge) {
        setNewUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.xl &&
              item.img_url.includes("NewUser")
          ).img_url
        );
        setCurrentUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.xl &&
              item.img_url.includes("CurrentUser")
          ).img_url
        );
      }
    }
  }, [banners, isLarge, isSmall, isTablet, isXlarge, isXsmall]);

  useEffect(() => {
    setEntriesFrom(
      moment(spanishCurrentRoundAndSubmission?.round?.roundEnd)
        .add(5, "hours")
        .format("ddd D MMMM [a las] HH:mm")
    );
  }, [spanishCurrentRoundAndSubmission?.round?.roundEnd]);

  //initialy check if render btn
  useEffect(() => {
    if (userAddedPredictions && !editMode) {
      setRenderBtn(false);
    } else {
      setRenderBtn(true);
    }
  }, [userAddedPredictions, editMode]);

  useEffect(() => {
    if (userAddedPredictions && setEditMode && nextRound) {
      setEditMode(false);
    }
  }, []);

  useEffect(() => {
    if (user && !hasNickname) {
      if (askUserForNicknameAttemptsLeft.current > 0) {
        askUserForNicknameAttemptsLeft.current--;
        setShowNicknameModal(true);
      }
    }
  }, [askUserForNicknameAttemptsLeft, hasNickname, user]);

  //if user sent predictions, make put request to edit
  useEffect(() => {
    if (userAddedPredictions) {
      setPostOrPut("put");
    } else {
      setPostOrPut("post");
    }
  }, [userAddedPredictions]);

  useEffect(() => {
    setCampaign(campaign);
  }, [campaign]);

  const handleClose = () => {
    if (!hasNickname) {
      setShowNicknameModal(true);
    } else {
      history.push(`${location.pathname}/1x2`);
    }
  };
  const handleShowNicknameModal = () => {
    setShowNicknameModal(true);
  };
  //

  //prediction scores array
  const [predictions, setPredictions] = useState([]);

  //if user sent predictions, make put request to edit
  useEffect(() => {
    if (userAddedPredictions) {
      setPostOrPut("put");
    } else {
      setPostOrPut("post");
    }
  }, [userAddedPredictions, checkedNickname, user]);

  useEffect(() => {
    if (isAfterLocalForage && !hasNickname) {
      handleShowNicknameModal();
      setIsAfterLocalForage(false);
    }
  }, [isAfterLocalForage, hasNickname, setIsAfterLocalForage]);

  useEffect(() => {
    setSavedPredictions(predictions);
  }, [predictions]);

  const getPrediction = useCallback((prediction, index) => {
    if (prediction.matchId) {
      setPredictions((prevPredictions) => {
        const updated_predictions = [...(prevPredictions || [])];
        updated_predictions[index] = prediction;
        setPrediction(updated_predictions);
        return updated_predictions;
      });
    }
  }, []);

  const predictionReq = {
    email: "blabla@gmail.com",
    roundId: roundId,
    predictions: predictions,
  };

  // let buttonState = "button-disabled";
  useEffect(() => {
    const isNullPrediction = predictions?.filter((prediction) => {
      return prediction?.homeGoals !== null && prediction?.awayGoals !== null;
    });

    if (matches?.length && isNullPrediction?.length === matches?.length) {
      // setButtonState("button");
      setDisabled(false);
    }
  }, [matches?.length, predictions]);

  //post req for Predictions
  const sendPostReq = async () => {
    console.log("====sendPostReq====");
    setDisabled(true);
    // setButtonState("button-disabled");
    let checkPredictionsArray = predictions.filter((prediction) => {
      if (
        isNullOrUndefined(prediction?.homeGoals) ||
        isNullOrUndefined(prediction?.awayGoals)
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (checkPredictionsArray.length > 0) {
      setErrorMessage("Please do not leave an empty prediction.");
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      setErrorMessage("");

      if (!axios.defaults.headers.common["jwt-token"]) {
        await localforage.setItem("predictionArray", predictionArray);
        await localforage.setItem("nextRoundId", roundId);
        if (+sessionStorage.getItem("isMobile") === 1) {
          window.location.assign(
            LOGIN_URL_PROD //TODO: when link is ready update this
          );
        } else {
          window.location.assign(
            LOGIN_URL_PROD //TODO: when link is ready update this
          );
          window.top.location.href = LOGIN_URL_PROD; //TODO: when link is ready update this
          window.parent.location = LOGIN_URL_PROD;
        }
        return;
      }

      if (postOrPut === "post") {
        axios
          .post(`${BASE_URL}/api/add-predictions`, predictionReq, {
            headers: {
              "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // if (isBrowser) {
              // console.log("Submission Success - WEB");
              // ReactGA.event({
              //   category: "Submission Success - WEB",
              //   action: `${username} sent a new submission from WEB`,
              // });
              // }

              // if (isIOS) {
              // console.log("Submission Success - IOS");
              // ReactGA.event({
              //   category: "Submission Success - IOS",
              //   action: `${username} sent a new submission from IOS`,
              // });
              // }
              // if (isAndroid) {
              // console.log("Submission Success - ANDROID");
              // ReactGA.event({
              //   category: "Submission Success - ANDROID",
              //   action: `${username} sent a new submission from ANDROID`,
              // });
              // }
              setAccum1x2(
                response?.data?.roundMatches
                  ?.map((match) => {
                    return match?.markets.find(
                      (market) => market.marketType === 1
                    );
                  })
                  ?.map((outcom) => {
                    return outcom?.possibleOutcomes?.find(
                      (selected) => selected.isSelected === true
                    );
                  })
                  ?.map((selected) => selected?.id)
              );
              setApiCallWasMade(!apiCallWasMade);
              //setAccumulator(response?.data);
              setPostOrPut("put");

              if (!hasNickname) handleShowNicknameModal();
              setModalActive(true);
              (async () => {
                await localforage.removeItem("nextRoundId");
                await localforage.removeItem("predictionArray");
              })();
            }
            setIsDataOld(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (postOrPut === "put") {
        setShowModal(true);
      }
    }
  };

  const debouncedSubmit = useDebounce(sendPostReq);

  const redirectBanner = () => {
    const os = sessionStorage.getItem("os");
    if (!user) {
      if (os) {
        if (os === "ios") {
          window.open(
            "https://www.codere.mx/login?clientType=sportsbook&deliveryPlatform=Native&advertiser=MVP_iOS_MX&back_url=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere",
            "_blank"
          );
        } else {
          window.open(
            "https://www.codere.mx/login?clientType=sportsbook&deliveryPlatform=Native&advertiser=MVP_Android_MX&back_url=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere",
            "_blank"
          );
        }
      } else {
        window.open(
          "https://www.codere.mx/registro-deportes?clientType=sportsbook&mvp=1&back_url=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere&login_action=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere",
          "_blank"
        );
      }
    } else {
      history.push(`${location.pathname}/1x2`);
      return;
    }
  };

  const checkedPredictions = userAddedPredictions?.reduce(
    (acc, curr) => acc + (curr.status !== "pending" ? 1 : 0),
    0
  );

  if (!loading) {
    return (
      <div className="mtches-body min-vh-100">
        <div className="px-2 container-md flex-wrap">
          <div style={{ paddingTop: `env(safe-area-inset-top, 50px)` }}>
            <Header
              pageName={PagesEnum.predictions}
              userAddedPredictions={userAddedPredictions}
              lastRound={lastRound}
            ></Header>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div className="edit-prediction-btn">
                {!lastRound && (
                  <EditBtn
                    userAddedPredictions={userAddedPredictions}
                    editMode={editMode}
                    setEditMode={setEditMode}
                  />
                )}
              </div>
            </div>
            {!nextRound && !!entriesFrom && (
              <div className="text-center">
                <p style={{ color: "#79C000" }}>
                  La siguiente jornada se abrirá a partir del&nbsp;
                  {entriesFrom}
                </p>
              </div>
            )}

            {!matches && !loading ? (
              <h2 className="text-center">
                There are currently no predictions to show
              </h2>
            ) : (
              <div>
                {!nextRound && checkedPredictions ? (
                  <div>
                    <MatchesSuccessIndicators
                      submissionResults={userAddedPredictions}
                      matches={matches}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {/* {currentRound && (
                  <div
                    onClick={redirectBanner}
                    style={{ marginTop: isTablet && !isLarge && "3.75rem" }}
                  >
                    <img
                      style={{ width: "100%", cursor: "pointer" }}
                      src={user ? currentUserBanner : newUserBanner}
                      alt="predictions banner"
                    />
                  </div>
                )} */}
                {matches &&
                  matches
                    .sort(
                      (a, b) =>
                        new Date(a.match_start) - new Date(b.match_start)
                    )
                    .map((match, index, array) => (
                      <Match
                        userAddedPrediction={
                          userAddedPredictions?.length
                            ? userAddedPredictions.find(
                                (prediction) => prediction.matchId === match.id
                              )
                            : undefined
                        }
                        statusCode={match.status_code}
                        key={match.id}
                        homeTeamName={match.homeTeam.name}
                        awayTeamName={match.awayTeam.name}
                        match_start={moment(match?.match_start).format(
                          "ddd, D MMMM YYYY"
                        )}
                        filterdDates={
                          moment(match?.match_start).format(
                            "ddd, Do MMM YYYY"
                          ) !==
                          moment(array[index - 1]?.match_start).format(
                            "ddd, Do MMM YYYY"
                          )
                            ? moment(match.match_start).format(
                                "ddd, D MMMM YYYY"
                              )
                            : null
                        }
                        match_hour={match.match_start}
                        matchId={match.id}
                        getPrediction={getPrediction}
                        campaign={campaign}
                        index={index}
                        awayTeamShirt={match?.awayTeam?.shirt_img}
                        homeTeamShirt={match?.homeTeam?.shirt_img}
                        matchScore={match?.matchScore}
                        currentRound={currentRound}
                        nextRound={nextRound}
                        editMode={editMode}
                        lastRound={lastRound}
                        savedPrediction={savedPredictions?.find(
                          (sp) => sp.matchId === match.id
                        )}
                      />
                    ))}
              </div>
            )}
            {nextRound && renderBtn ? (
              <CostumButton goTo={campaign}>
                <button
                  disabled={disabled}
                  onClick={debouncedSubmit}
                  data-target="#myModal"
                  className={`${disabled ? "button-disabled" : "button"} my-4`}
                >
                  GUARDAR
                </button>
              </CostumButton>
            ) : !renderBtn ? (
              <div style={{ marginBottom: "6em" }}></div>
            ) : (
              <div style={{ marginBottom: "6em" }}></div>
            )}
            <ModalSubmit
              roundId={roundId}
              ready={showModal}
              setReady={setShowModal}
              afterClose={handleClose}
            />
            {/*             <PredictionsModal
              setShow={setShow}
              onHide={handleClose}
              show={show}
              accum1x2={accum1x2}
            /> */}
            <NicknameModal
              onCloseModal={() => {
                // history.push(`${location.pathname}/1x2`);
                setShowNicknameModal(false);
              }}
              isOpen={showNicknameModal}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Spinner
          className="spinner"
          animation="border"
          style={{ color: "rgba(121, 192, 0, 1)" }}
        />
      </div>
    );
  }
};

export default Matches;
