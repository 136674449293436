import React from "react";
import "./WelcomeItem.css";

const WelcomeItem = ({ index, icon, title, text }) => {
  return (
    <div className="container__welcomeItem">
      <div className="indexNumberBox__welcomeItem">
        <div className="indexContent__welcomeItem">{index}</div>
      </div>
      <div className="icon__welcomeItem">
        <img src={icon} alt="welcome item icon"/>
      </div>
      <div className="content__welcomeItem">
        <div className="title__welcomeItem">{title}</div>
        <div className="text__welcomeItem">{text}</div>
      </div>
    </div>
  );
};

export default WelcomeItem;
