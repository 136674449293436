import React from "react"
import arrowRight from "../images/results/arrowRight.svg"
import moment from "moment"

const chooseRoundStyle = {
  fontWeight: 500,
  fontSize: "0.9rem",
  marginBottom: 0,
  color: "rgba(151, 151, 151, 1)",
  marginTop: 7,
}

const chooseDateStyle = {
  fontWeight: 500,
  fontSize: "0.8em",
  marginBottom: 7,
}

const greenIndicator = {
  width: 12,
  height: 6,
  background: "rgb(30 113 4)",
  InnerShadow: "rgba(255, 255, 255, 0.25)",
  boxShadow: "inset 0px 4px 4px rgba(255, 255, 255, 0.25)",
  borderRadius: 5,
  marginRight: 3,
  marginTop: 13,
}

const redIndicator = {
  ...greenIndicator,
  background: "rgba(224, 0, 0, 1)",
}

const grayIndicator = {
  ...greenIndicator,
  background: "#D6D6D6",
}

const greenLightIndicator = {
  ...greenIndicator,
  background: "rgb(184 234 30)",
}

function PastResults({ round, submission }) {
  const successOrFailure = submission?.matchPredictions.sort((sof1, sof2) => {
    const [matchStartOfsof1, matchStartOfsof2] = [
      +new Date(
        round?.matches?.find((m) => m?.id === sof1?.matchId)?.match_start
      ), //we sort the prediction by the matches start time order
      +new Date(
        round?.matches?.find((m) => m?.id === sof2?.matchId)?.match_start
      ),
    ]

    return -matchStartOfsof2 + matchStartOfsof1 || 1 //fallback
  })

  const successMatches = successOrFailure?.filter((match) => {
    return match.status === "success"
  })

  const howManySuccessMatches = successMatches?.length
  const withBorder = "row border-bottom"
  const withoutBorder = "row"

  if (submission?.matchPredictions || round)
    return (
      <div
        style={{
          marginTop: "1.5em",
          fontWeight: 500,
        }}
        className="container"
      >
        <div className="container">
          <div className={submission ? withoutBorder : withBorder}>
            <div className="col-5 col-md-4">
              <p style={chooseRoundStyle}>Jornada {round.name}</p>
              <p className="mb-0" style={chooseDateStyle}>
                {round?.matches[0]?.match_start &&
                  moment(round?.matches[0]?.match_start)?.format("D MMM YYYY")}
              </p>
              <p style={{ color: "#3788FF", fontSize: "0.9rem" }}>
              puntos totales: {submission?.successPoints}
              </p>
            </div>
            <div className="col-5 col-md-4">
              <p className="mb-0 text-center">{howManySuccessMatches}/6</p>

              <div className="d-flex justify-content-center mx-1">
                {successOrFailure?.map((singleMatch, index) => {
                  if (singleMatch.status === "pending") {
                    return <div key={index.toString()} style={grayIndicator} />
                  } else if (singleMatch.successPoints === 5) {
                    return <div key={index.toString()} style={greenIndicator} />
                  } else if (singleMatch.successPoints === 0) {
                    return <div key={index.toString()} style={redIndicator} />
                  } else if (singleMatch.successPoints === 2) {
                    return (
                      <div key={index.toString()} style={greenLightIndicator} />
                    )
                  }
                })}
              </div>
            </div>
            <div className="col-2 mt-2 col-md-4 text-center">
              <img src={arrowRight} alt="arrow-right" />
            </div>
          </div>
        </div>
      </div>
    )
}

export default PastResults
