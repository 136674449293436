import React from "react";
import Header from "../Header/Header";
import "./TermsConditions.css";

const TermsConditions = () => {
  return (
    <div className="terms">
      <Header TermsConditions />
      <div style={{ padding: "9em 1em 5em 1em" }}>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1.5em",
            textTransform: "uppercase",
          }}
          //       style="text-transform: uppercase !important;
          //   text-align: center;font-weight:bold font-size: 1.5em;"
        >
          TÉRMINOS Y CONDICIONES: MOST VALUED PLAYER (MVP) DE CODERE.COM.CO
        </p>
        <p>
          1. RESTRICCIONES GENERALES: Esta es una promoción de Codere Colombia
          S.A (en adelante, Codere), con domicilio principal en Bogotá,
          Colombia, Ak 103 No 25F-12 Bogotá y cuyo procesamiento de pagos son
          operados por Codere Apuestas S.A.U. Está licenciada en Colombia por
          COLJUEGOS con el número de concesión C1470 del 10 de noviembre de 2017
          al 9 noviembre de 2022. Promoción válida exclusivamente para usuarios
          que ingresen a través de codere.com.co residentes en Colombia
          exclusivamente mayores de 18 años, que estén registrados como usuarios
          en codere.com.co.
        </p>
        <p>
          2. PERIODO: Del 24 de Enero 2022 a las 0:01 al 10 de Diciembre 2022 a
          las 23:59 hora de Colombia.
        </p>
        <p>
          3. EXPLICACIÓN: Para ser ganador de los premios de la siguiente
          actividad promocional, el usuario deberá realizar una predicción sobre
          el número de goles marcados por cada equipo (marcador exacto) de los 6
          partidos propuestos por Codere para cada jornada (en adelante, “el
          Torneo”) y realizar, al menos, los aciertos indicados en el numeral
          cuarto de estos términos y condiciones.
        </p>
        <p>4. Dependiendo del número de aciertos los premios serán:</p>
        <div className="table container">
          <div className="d-flex">
            <div className="border p-2 col-6">3 aciertos</div>
            <div className="border p-2 col-6">$100.000 en freebets</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">4 aciertos</div>
            <div className="border p-2 col-6">Camiseta del Real Madrid.</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">5 aciertos</div>
            <div className="border p-2 col-6">XBOX</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">6 aciertos</div>
            <div className="border p-2 col-6">
              EXPERIENCIA VIP con River Plate en Argentina. Estaría incluido
              para 2 personas: 2 billetes, vuelo (saliendo desde Bogotá), hotel
              y alimentación.
            </div>
          </div>
        </div>
        <p>
          *Cada premio es independiente y va asociado al número de aciertos.
          Estos freebets se entregarán en un plazo máximo de 3 días hábiles.{" "}
          <br />
          Por cada participación los usuarios podrán ir ganando puntos para el
          ranking de MVP.
          <br /> Los puntos que obtengan se irán acumulando por cada
          participación. <br />
          Los puntos que se acumulan para el ranking se ganan por acertar en el
          marcador final del partido.
        </p>
        <p>Tabla de puntos por partido para acumular al ranking MVP:</p>
        <table className="table container col-3 border">
          <thead>
            <tr>
              <th>Aciertos</th>
              <th>Puntos</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1 Resultado</td>
              <td>2</td>
            </tr>
            <tr>
              <td>2 Resultados</td>
              <td>5</td>
            </tr>
          </tbody>
        </table>
        <p>
          *Si el usuario ha acertado un 1 resultado de un partido, es decir, si
          dijo que el partido quedaría 1-2 y ha quedado 1-1, obtendrá 2 puntos
          ya que acertó un resultado del marcador. Si acierta el resultado del
          partido obtendrá los 5 puntos. El ganador del Ranking hasta el último
          día del mes de Junio 2022, ganará un cupo para participar en la Copa
          Codere en Colombia junto a un grupo de sus amigos. Si su equipo llega
          a la final, podrán jugar la Copa en Madrid.
        </p>

        <p>
          Además, los usuarios que participen en la promoción de forma
          consecutiva durante al menos dos jornadas, recibirán, una vez acabada
          la promoción:
        </p>
        <div className="table container">
          <div className="d-flex">
            <div className="border p-2 col-6">2 semanas seguidas</div>
            <div className="border p-2 col-6">$10.000 en freebets</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">3 semanas seguidas</div>
            <div className="border p-2 col-6">$10.000 en freebets</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">4 semanas seguidas</div>
            <div className="border p-2 col-6">$20.000 en freebets</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">5 semanas seguidas</div>
            <div className="border p-2 col-6">$50.000 en freebets</div>
          </div>
        </div>
        <p>
          Estos freebets se entregarán tras finalizar cada jornada en un plazo
          de 3 días laborables.
        </p>
        <div>
          <p>5. La Experiencia VIP consistirá en:</p>
          <ul>
            <li>
              Una experiencia VIP que vivirá el ganador junto con su acompañante
              en Argentina con el Club Atlético River Plate.
            </li>
            <li>
              Estaría incluido vuelo de ida y vuelta (saliendo desde Bogotá),
              hotel y alimentación.
            </li>
            <li>
              El premio Experiencia VIP no incluye ningún importe ni gasto no
              expresamente incluido en los presentes Términos y Condiciones.
            </li>
            <li>
              En particular, deberán correr a cargo del ganador cualquier gasto
              de transporte, alojamiento y manutención adicional, en los que
              pudiera incurrir con el objeto de disfrutar del premio.
            </li>
            <li>Se comunicará con el ganador por vía telefónica o email.</li>
          </ul>
        </div>
        <p>6.Todos los usuarios con 6 aciertos ganarán la Experiencia VIP.</p>
        <div>
          <p>
            7. Por “freebets” se entenderá como créditos promocionales para la
            participación de apuestas y sorteos concedido por codere.com.co para
            su uso exclusivo en el sitio web codere.com.co Los freebets deberán
            ser utilizadas en base a los Términos y Condiciones de codere.com.co
            y, en todo caso:
          </p>
          <ul>
            <li>
              No podrán ser utilizadas para realizar apuestas a Galgos o a
              Caballos.
            </li>
            <li>
              Deberán utilizarse para realizar apuestas con una cuota igual o
              superior a 1,5.
            </li>
            <li>
              Los freebets tendrán una vigencia de 7 días desde su obtención.
              Transcurrido dicho periodo, los freebets no utilizadas serán
              eliminados.
            </li>
            <li>
              Al usar los freebets, en caso de que la apuesta sea ganadora, el
              usuario solo recibirá la ganancia neta derivada de las mismas
              (Ganancia - Importe de los freebets).
            </li>
            <li>
              Al usar los freebets, en caso de que la apuesta resulte cancelada
              no se devolverá el importe de los freebets ni se asignará otra en
              su lugar.
            </li>
            <li>
              Una vez se establezca el primer contacto con el ganador, este
              tendrá un plazo de 48 horas para confirmar los datos requeridos de
              validación y participación en la mecánica, de no contestar dentro
              de este plazo, el premio quedará desierto. Los premios tangibles
              serán entregados en un plazo de 30 días mediante agencia de envíos
              y los envíos se realizarán una vez se reciba el formato de acta de
              entrega firmado por el ganador.
            </li>
          </ul>
        </div>
        <p>
          8. El ganador aceptará el premio tal y como se ofrece y no incluye
          ningún otro concepto que no esté especificado en los presentes
          Términos y Condiciones. El premio es personal e intransferible, no
          pudiendo cederse a terceras personas. Solo se permite una
          participación por usuario en cada jornada. Estos términos y
          condiciones se aplicarán desde que se autorice la presente promoción.
        </p>
        <p>
          <b>No se tendrán en cuenta para esta promoción:</b>
          <br />
          Apuestas que no cumplan los requisitos anteriormente especificados, o
          las que se cancelen o cierren con anterioridad o hayan sido realizadas
          con freebets. No se tendrán en cuenta los tickets que contengan
          apuestas de sistema. Esta promoción no se puede usar junto con ninguna
          otra oferta.
        </p>
        <p>
          9. La fecha final de la promoción será el 31 de Diciembre de 2022. Si
          llegada dicha fecha, no hubiera habido ningún participante con 6
          aciertos, el premio Experiencia VIP, quedará desierto.
        </p>
        <p>
          10. Se podrán hacer y modificar las predicciones hasta el comienzo del
          primer partido de cada jornada. Una vez iniciado dicho primer partido,
          no se admitirán más predicciones.
        </p>
        <p>
          11. Se tendrán en cuenta los goles marcados en los 90 minutos de juego
          y no los marcados en los tiempos extra.
        </p>
        <p>
          12. Si uno de los 6 partidos se pospone, cancela o no concluye, el
          primer premio (Experiencia VIP) se declarará desierto. En el caso de
          que dos de los 6 partidos se pospongan, cancelen o no concluyan, el
          primer premio (Experiencia VIP,) y el segundo premio (Videoconsola
          Xbox) quedarán desiertos, y así sucesivamente.
        </p>
        <p>
          13. VERIFICACIÓN DE IDENTIDAD: Para desbloquear el uso de la freebet
          es obligatorio pasar el proceso de verificación de identidad, que
          consiste en enviar a Codere Apuestas: Cédula de Ciudadanía y/o Cédula
          de Extranjería.
        </p>
        <div>
          <p>
            14. OTRAS RESTRICCIONES DE PARTICIPACIÓN: No podrán participar los
            empleados y familiares directos del organizador o de sus empresas
            asociadas, los asesores profesionales, las agencias de publicidad y
            de promoción que tengan relación con el organizador. No podrán
            participar tampoco aquellos que se encuentren inscritos en el
            registro de autoexclusión de acceso al juego, así como también:
          </p>
          <ul>
            <li>Menores de 18 años.</li>
            <li>Los incapacitados legalmente.</li>
            <li>
              Deportistas, sus agentes u otros participantes directos en el
              acontecimiento objeto de las apuestas.
            </li>
            <li>
              Los árbitros o jueces que ejerzan sus funciones en el
              acontecimiento objeto de las apuestas, así como las personas que
              resuelvan los recursos contra las decisiones de aquellos.
            </li>
            <li>
              Personal empleado en los locales de apuestas y en las zonas de
              apuestas.
            </li>
          </ul>
        </div>
        <div>
          <p>
            15. El organizador no tendrá obligación de entregar el premio en los
            supuestos siguientes:
          </p>
          <ul>
            <li>
              Si hay sospecha de incumplimiento de los presentes términos y
              condiciones o de las condiciones generales de contratación
              aceptadas por el cliente, en particular en lo relativo a la
              veracidad de datos y a la prohibición de registrar más de un
              usuario por persona física, sin perjuicio de las acciones
              adicionales que procedieran por tal incumplimiento.
            </li>
            <li>
              Si hay sospecha de que un cliente ha utilizado diferentes recursos
              para desvirtuar el objeto de la promoción.
            </li>
            <li>
              Si el cliente no aceptara recibir comunicaciones publicitarias por
              correo electrónico, SMS u otro medio de comunicación equivalente.
            </li>
            <li>
              Si un jugador apuesta a todas las posibilidades reduciendo a cero
              la posibilidad de pérdida, ya sea directamente con su cuenta o
              mediante comportamiento coludido con otros.
            </li>
            <li>
              Antes de ser cobrado, Codere verificará si dichas condiciones se
              han cumplido correctamente, y se reserva el derecho a cancelar el
              ingreso del freebet si considera que ha habido un abuso de la
              campaña o se ha cometido fraude.
            </li>
            <li>Todas las ofertas se limitan a una por persona.</li>
            <li>
              Solo se permite una participación en cada torneo por usuario.
            </li>
          </ul>
        </div>
        <p>
          16. Codere se reserva el derecho de modificar, alterar, suspender o
          finalizar esta promoción en cualquier momento.
        </p>
        <p>
          17. Los clientes de Codere Apuestas que participen en la promoción
          aceptan los términos y condiciones y reconocen que el incumplimiento
          de las mismas puede provocar su descalificación.
        </p>
      </div>
      <p>
        Código Promo: CO.21.09.21-MVP-39.0 <br /> IR A CODERE
      </p>
    </div>
  );
};

export default TermsConditions;
