import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { SideNavContext } from "./contexts/SideNavContext";
import GreenInvite from "./images/sideNavDesktop/GreenInvite.svg";
import tour from "./images/mobile-footer/tour.png";
import { shareUrlWithFriends } from "./environment";
import termsGreen from "./images/sideNavDesktop/Terms-Green.png";
import Share from "./components/Share/Share";

const SideNav = ({ platforms }) => {
  const { sideBar, setSidebar } = useContext(SideNavContext);
  const {
    params: { firstParam, secondParam },
  } = useRouteMatch({
    path: ["/:firstParam/:secondParam", "/:firstParam", "/"],
  });
  const [showShareModal, setShowShareModal] = useState(false);
  const sideNavRef = useRef();

  let paddingTop;
  if (["1x2", "u-o-2.5-goals", "bbts"].includes(secondParam)) {
    paddingTop = "18rem";
  } else if (firstParam === "leaderboard") {
    paddingTop = "14rem";
  } else if (firstParam === "results-schedule") {
    paddingTop = "11rem";
  } else if (firstParam === "terms-conditions") {
    paddingTop = "9rem";
  } else if (firstParam === "how-to-play") {
    paddingTop = "7rem";
  } else {
    paddingTop = "17rem";
  }
  const clickShare = () => {
    setShowShareModal(true);
  };
  //prediction and accumulator positioning
  let navContainerStyle = {
    transition: "left 400ms ease-in",
    position: "fixed",
    backgroundColor: "#FFFFFF",
    left: "-6rem",
    bottom: 0,
    top: 0,
    boxShadow: "5px 0px 10px rgba(180, 180, 180, 0.25)",
    zIndex: 200,
    marginTop: -30,
    width: "6rem",
    paddingTop: `calc(${paddingTop} + env(safe-area-inset-top, 600))`,
  };

  const navContainerActive = {
    ...navContainerStyle,
    transition: "400ms ease-out",
    left: 0,
  };

  const imgStyle = {
    transform: "translateX(-50%)",
    left: "50%",
    position: "relative",
  };

  const text = {
    fontSize: 12,
    textAlign: "center",
    color: "rgba(91, 91, 91, 1)",
    marginBottom: 0,
  };

  const textTour = {
    color: "rgba(121, 192, 0, 1)",
    fontSize: 12,
    textAlign: "center",
    marginBottom: 0,
  };

  const navItem = {
    paddingTop: 17,
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
        setSidebar(false);
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sideNavRef, setSidebar]);

  // const goToDeposite = () => {
  //   window.open(
  //     "https://www.codere.mx/deposito?clientType=sportsbook&amp;back_url=https%3A%2F%2Fbet.codere.mx%2Fhub-landing&amp;deliveryPlatform=Native"
  //   );
  // };

  return (
    <div ref={sideNavRef}>
      <div
        className="nav-container"
        style={sideBar ? navContainerActive : navContainerStyle}
      >
        <ul
          style={{ paddingTop: "env(safe-area-inset-top)" }}
          className="navbar-nav"
        >
          <li style={navItem} className="nav-item">
            <Link to="/how-to-play" className="nav-link">
              <div>
                <img
                  onClick={() => setSidebar(false)}
                  src={tour}
                  alt="how to play"
                  style={imgStyle}
                />
                <p style={textTour}>Cómo jugar</p>
              </div>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/terms-conditions" className="nav-link">
              <img
                onClick={() => setSidebar(false)}
                src={termsGreen}
                alt=""
                style={imgStyle}
              />
              <p style={textTour}>Terms & conditions</p>
            </Link>
          </li>
          <li className="nav-item">
            <div className="dektop-sidenav-img" onClick={clickShare}>
              <img style={{ width: "41px" }} src={GreenInvite} alt="share" />
              <p style={textTour}>Invita a un amigo</p>
            </div>
          </li>
        </ul>
      </div>
      {showShareModal && (
        <Share
          title={
            <>
              <span style={{ fontWeight: "bold" }}>
                ¡Comparte MVP y reta a tus amigos!
              </span>
            </>
          }
          linkUrl={shareUrlWithFriends}
          platforms={platforms}
          ready={showShareModal}
          setReady={setShowShareModal}
        />
      )}
    </div>
  );
};

export default SideNav;
