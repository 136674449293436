import SuccessPoints from "./../enums/success-points"
function RoundSummary({ submission }) {
  const submissionSuccessPoints = submission?.matchPredictions?.map(
    (mp) => mp?.successPoints ?? 0
  )
  return (
    <div className="pb-4">
      <div className="round-summary_hl">Resumen de la jornada</div>
      <div className="round-summary_container">
        <div className="round-summary_head">
          <div className="round-summary_cell"></div>
          <div className="round-summary_cell text-center">#</div>
          <div className="round-summary_cell text-center">Puntos</div>
        </div>
        <div className="round-summary_row">
          <div className="round-summary_cell">Resultado Correcto</div>
          <div className="round-summary_cell text-center">
            {
              submissionSuccessPoints?.filter(
                (sp) => sp === SuccessPoints.CorrectResult
              )?.length
            }
          </div>
          <div className="round-summary_cell text-center">
            {submissionSuccessPoints
              ?.filter((sp) => sp === SuccessPoints.CorrectResult)
              ?.reduce((prev, curr) => prev + curr, 0)}
          </div>
        </div>
        <div className="round-summary_row">
          <div className="round-summary_cell">Resultado Exacto</div>
          <div className="round-summary_cell text-center">
            {
              submissionSuccessPoints?.filter(
                (sp) => sp === SuccessPoints.CorrectScore
              )?.length
            }
          </div>
          <div className="round-summary_cell text-center">
            {submissionSuccessPoints
              ?.filter((sp) => sp === SuccessPoints.CorrectScore)
              ?.reduce((prev, curr) => prev + curr, 0)}
          </div>
        </div>
        <div className="round-summary_row">
          <div className="round-summary_cell">Total</div>
          <div className="round-summary_cell text-center">
            {submissionSuccessPoints?.filter((sp) => sp).length}
          </div>
          <div className="round-summary_cell text-center">
            {submissionSuccessPoints?.reduce((prev, curr) => prev + curr, 0)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoundSummary
