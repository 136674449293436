import React from "react"
import Moment from "react-moment"
import moment from "moment"

function Date({ filterdDates, match_start }) {
  return (
    <div className="container-fluid date-time">
      <p className="date my-2">{filterdDates || match_start}</p>
    </div>
  )
}

export default Date
