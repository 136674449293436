import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import "./RSbody.css";

import hapyTeam from "../images/results/hapyTeam.png";
import { ResultsScheduleContext } from "../contexts/ResultsScheduleContext";
import PastResults from "./PastResults";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import moment from "moment";
import Banner from "../GeneralComponents/Banner";
import { AccumulatorContext } from "../contexts/AccumulatorContext";
import { PredictionsContext } from "../contexts/PredictionsContext";
import "moment/locale/es";

import { CURRENT_CAMPAIGN_ENDPOINT } from "../leagues/leagues";
import isNullOrUndefined from "./../helpers/is-null-or-undefined";
import DesktopHeader from "../Header/DesktopHeader";
import BannerSizeEnum from "../enums/banners-size";
import PagesEnum from "../enums/pages";

moment.updateLocale("es", {
  weekdaysShort: "dom_lun_mar_mié_jue_vie_sáb".split("_"),
});

const linkStyle = { textDecoration: "none", color: "rgba(53, 61, 73, 1)" };

const whatRoundStyle = {
  fontWeight: 500,
  fontSize: 16,
  marginBottom: 0,
  color: "#FFFFFF",
};

const nextRoundLeftBox = {
  background: "linear-gradient(155.76deg, #353D49 2.12%, #3C5C8B 104.2%)",
  borderRadius: "10px 0px  0px 10px",
};

const untillWhenStyle = {
  ...whatRoundStyle,
  fontWeight: 700,
};

const textDivStyle = {
  position: "relative",
  marginTop: "3em",
};

const backgroungImage = {
  backgroundImage: "url(" + hapyTeam + ")",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  backgroundPosition: "center",
  backgroundColor: "black",
  borderRadius: "0 10px 10px 0",
};

const borders = {
  width: 10,
  padding: 0,
  height: 0.5,
  background: "rgba(218, 218, 218, 1)",
  display: "inline",
  marginTop: 10,
};

const RSbody = (props) => {
  const { spanishPastRoundPredictions, spanishNextRound } = useContext(
    ResultsScheduleContext
  );

  const { banners } = useContext(AccumulatorContext);

  const { spanishCurrentRoundAndSubmission, spanishLastRoundAndSubmission } =
    useContext(PredictionsContext);

  let location = useLocation();
  let history = useHistory();
  let {
    params: { league: currentLeagueInRoute },
  } = useRouteMatch("/results-schedule/:league");

  const [nextRoundName, setNextRoundName] = useState(null);
  const [nextRoundTime, setNextRoundTime] = useState(moment());
  const [isEntriesUntilOrFrom, setIsEntriesUntilOrFrom] = useState("until");

  const [banner, setBanner] = useState(null);

  const isXsmall = useMediaQuery({ query: "(max-width: 500px)" });
  const isSmall = useMediaQuery({ query: "(min-width: 500px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 600px)" });

  const isAndroid = sessionStorage.getItem("os") === "android";

  useEffect(() => {
    if (banners) {
      if (isXsmall) {
        setBanner(
          banners.find((item) => item.size === BannerSizeEnum.xs).img_url
        );
      }
      if (isSmall) {
        setBanner(
          banners.find((item) => item.size === BannerSizeEnum.s).img_url
        );
      }
      if (isTablet) {
        setBanner(
          banners.find((item) => item.size === BannerSizeEnum.m).img_url
        );
      }
    }
  }, [banners, isSmall, isTablet, isXsmall]);

  //choosing what round number to display according to url
  useEffect(() => {
    if (!spanishCurrentRoundAndSubmission) {
      if (spanishNextRound) {
        setNextRoundName(spanishNextRound.name);
        setNextRoundTime(
          moment(spanishNextRound?.roundStart).subtract(1, "hours")
        );
        setIsEntriesUntilOrFrom("until");
      } else {
        setNextRoundName(spanishLastRoundAndSubmission?.round?.nextRoundName);
        setNextRoundTime(
          moment(spanishLastRoundAndSubmission?.round?.roundEnd).add(5, "hours")
        );
        setIsEntriesUntilOrFrom("from");
      }
    } else {
      setNextRoundName(spanishCurrentRoundAndSubmission?.round?.nextRoundName);
      setNextRoundTime(
        moment(spanishCurrentRoundAndSubmission?.round?.roundEnd).add(
          5,
          "hours"
        )
      );
      setIsEntriesUntilOrFrom("from");
    }
  }, [
    spanishCurrentRoundAndSubmission,
    spanishLastRoundAndSubmission?.round?.nextRoundName,
    spanishLastRoundAndSubmission?.round?.roundEnd,
    spanishNextRound,
  ]);

  function getTotalSuccessPoints() {
    return spanishCurrentRoundAndSubmission?.submission ||
      spanishPastRoundPredictions?.length
      ? (
          (spanishCurrentRoundAndSubmission?.submission && [
            spanishCurrentRoundAndSubmission,
          ]) ||
          []
        )
          .concat(...(spanishPastRoundPredictions || []))
          ?.map((r) => r?.submission?.successPoints || 0)
          ?.reduce((prev, curr) => (prev || 0) + (curr || 0), 0) || ""
      : null;
  }

  let nextRoundDateFormatedOnlyMonth = moment(nextRoundTime).format("MMMM");
  let nextRoundDateFormatedOnlyDayMum = moment(nextRoundTime).format("D");
  let nextRoundDateFormatedOnlyDayName = moment(nextRoundTime).format("dddd");

  const checkPath = () => {
    return `/${CURRENT_CAMPAIGN_ENDPOINT}`;
  };

  let summery;
  if (
    location.pathname === `/results-schedule/${CURRENT_CAMPAIGN_ENDPOINT}` &&
    spanishPastRoundPredictions
  ) {
    summery = (
      <div
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          border: "#F5F5F5 solid 1px",
          borderRadius: "10px",
          padding: "1rem 1rem",
        }}
        className="d-flex"
      >
        <div>
          <span>Tu puntuación</span>
        </div>
        <div>
          <span>{`${getTotalSuccessPoints()} `}</span>
          <span style={{ color: "#A7A7A7" }}>Puntos</span>
        </div>
        <div>
          <button
            onClick={() => {
              history.push(`/leaderboard/${currentLeagueInRoute}`);
            }}
            className="leader-board-btn"
          >
            <p>Clasificación</p>
          </button>
        </div>
      </div>
    );
  }

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });
  return (
    <div className="RSbody">
      {isDesktopOrLaptop && <DesktopHeader pageName={PagesEnum.results} />}

      <div
        style={
          isDesktopOrLaptop
            ? {
                paddingTop: "2em",
                paddingBottom: "5rem",
                fontWeight: 500,
                maxWidth: 800,
                position: "relative",
              }
            : {
                paddingTop: "11em",
                paddingBottom: "5rem",
                fontWeight: 500,
                maxWidth: 900,
                background: "white",
                position: "relative",
              }
        }
        className="container"
      >
        <div style={{ paddingTop: `env(safe-area-inset-top, 100px)` }}>
          <>
            <div className="m-0 row">
              <div style={borders} className="col-3"></div>
              <p className="col-6 px-2 text-center">
                {nextRoundName || "Jornada"}
              </p>
              <div style={borders} className="col-3"></div>
            </div>
            {/* if current round is active, make the link go to the same page */}
            <Link to={checkPath()}>
              <div className="container">
                <div className="row">
                  <div style={nextRoundLeftBox} className="col-4 py-2">
                    <p
                      className="text-center mb-0"
                      style={{ color: "#ffffff", fontWeight: 500 }}
                    >
                      {nextRoundDateFormatedOnlyDayName}
                    </p>
                    <p
                      style={{ fontSize: 40, color: "#ffffff" }}
                      className="text-center mb-0"
                    >
                      {nextRoundDateFormatedOnlyDayMum}
                    </p>
                    <p
                      style={{ fontSize: "1.1rem", color: "#ffffff" }}
                      className="text-center mb-0"
                    >
                      {nextRoundDateFormatedOnlyMonth}
                    </p>
                  </div>
                  <div style={backgroungImage} className="col-8">
                    <div style={textDivStyle}>
                      <p style={untillWhenStyle}>
                        {nextRoundName || "Proxima jornada"}
                        <br />
                        Haz tu pronóstico{" "}
                        {isEntriesUntilOrFrom === "from"
                          ? "desde"
                          : "hasta"}{" "}
                        {nextRoundTime.format("dddd")}{" "}
                        {nextRoundTime.format("D")} de{" "}
                        {nextRoundTime.format("MMMM")} a las{" "}
                        {nextRoundTime.format("HH:mm")}
                        <br></br>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </>

          {!isNullOrUndefined(getTotalSuccessPoints()) ? (
            <div>
              <div className="container mt-3 mx-0 row">
                <div style={borders} className="col-3"></div>
                <p className="col-6 mb-0 px-0 text-center">Resumen</p>
                <div style={borders} className="col-3"></div>
              </div>
              {summery}
            </div>
          ) : (
            ""
          )}

          {location.pathname ===
            `/results-schedule/${CURRENT_CAMPAIGN_ENDPOINT}` &&
          spanishCurrentRoundAndSubmission?.submission ? (
            <div className="container m-0 mt-3 row">
              <div style={borders} className="col-3"></div>
              <p className="col-6 px-2 text-center">Current Round</p>
              <div style={borders} className="col-3"></div>
            </div>
          ) : null}

          {location.pathname ===
            `/results-schedule/${CURRENT_CAMPAIGN_ENDPOINT}` &&
          spanishCurrentRoundAndSubmission?.submission ? (
            <Link
              style={linkStyle}
              to={`${location.pathname}/${spanishCurrentRoundAndSubmission.round.id}`}
              // key={spanishCurrentRoundAndSubmission.round.id}
            >
              <PastResults
                round={spanishCurrentRoundAndSubmission?.round}
                submission={spanishCurrentRoundAndSubmission?.submission}
                key={spanishCurrentRoundAndSubmission?.submission?.id}
              ></PastResults>
            </Link>
          ) : null}

          <div className="container mt-3 mx-0 row">
            <div style={borders} className="col-3"></div>
            <p className="col-6 mb-0 px-0 text-center">Jornadas previas</p>
            <div style={borders} className="col-3"></div>
          </div>

          {spanishPastRoundPredictions <= 0 &&
          location.pathname ===
            `/results-schedule/${CURRENT_CAMPAIGN_ENDPOINT}` ? (
            <div>
              <p style={{ fontWeight: "bold" }} className="text-center">
                No puedes ver la jornada actual ni las anteriores, ya que aún no
                realizaste ningún pronóstico.
              </p>
              <Banner banner={banner}></Banner>
            </div>
          ) : null}

          {spanishPastRoundPredictions?.map((pastPrediction) => {
            return (
              <Link
                style={linkStyle}
                to={`${location.pathname}/${pastPrediction.round.id}`}
                key={pastPrediction.round.id}
              >
                <PastResults
                  round={pastPrediction?.round}
                  submission={pastPrediction?.submission}
                  key={pastPrediction?.submission?.id}
                  spanishSubmission
                ></PastResults>
              </Link>
            );
          })}
        </div>
        {/* <TermsFooter view="results" /> */}
      </div>
      {!isAndroid && (
        <div
          className="terms__RSbody"
          style={{ bottom: isTablet ? "2.25rem" : "5rem" }}
        >
          <Link to={"/terms-conditions"}>TÉRMINOS Y CONDICIONES</Link>
        </div>
      )}
    </div>
  );
};

export default RSbody;
