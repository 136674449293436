import { useMediaQuery } from "react-responsive";
import DesktopShare from "./DesktopShare/DesktopShare";
import MobileShare from "./MobileShare/MobileShare";
import "./Share.css";

const Share = (props) => {
  const isMobileScreen = useMediaQuery({
    query: "(max-width: 767px)",
  });

  if (isMobileScreen) {
    return <MobileShare {...props} />;
  }
  return <DesktopShare {...props} />;
};

export default Share;
