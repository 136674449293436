import { Grid, Switch, TextField } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { BASE_URL } from "../environment";
import codere_signup_logo from "../images/signup/codere_signup_logo.svg";
import arrowLeft from "../images/mobile-footer/arrowLeft.png";
import emailSentImg from "../images/forgotPassword/emailSent.svg";
import vi from "../images/forgotPassword/vi.png";

import VipLogo from "../Header/VipFormHeader";
// page styling

const mainGridStyle = {
  padding: 30,
  // minHeight: "vh",
  margin: "20px auto",
};
const inputGrid = {
  marginTop: 50,
};

const logInStyle = {
  fontWeight: "bold",
  fontSize: 30,
};

const textFieldStyle = {
  marginBottom: 20,
};

const footerStyle = {
  backgroundColor: "#353D49",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  paddingBottom: 250,
  marginTop: 70,
  bottom: 0,
};

// end of page styling
function ForgotPassword() {
  const [inputData, setInputData] = useState({
    password: "",
    passwordRetype: "",
  });

  const [error, setError] = useState({
    password: false,
    passwordRetype: false,
  });

  let btnClass = "button email-recover-btn-inactive";

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [emailSent, setEmailSent] = useState(false);

  if (btnDisabled === false) {
    btnClass = "button email-recover-btn-active";
  } else if (btnDisabled === true) {
    btnClass = "button email-recover-btn-inactive";
  }

  const history = useHistory();
  const location = useLocation();

  //extracting parms of user
  let queryStringSliced = location.search.slice(1);
  let splitQueryString = queryStringSliced.split("&");
  let sortedQueryString = splitQueryString.sort();
  let splitId = sortedQueryString[0].split("=");
  let splitToken = sortedQueryString[1].split("=");
  let idNum = splitId[1];
  let tokenNum = splitToken[1];

  //

  let passwordHelperText = "";
  if (error.email) {
    passwordHelperText = "Please enter a valid email.";
  }

  let passwordRetypeHelperText = "";
  if (error.passwordRetype) {
    passwordRetypeHelperText = "Password does not match.";
  }

  useEffect(() => {
    //password validation
    const passRegEx = new RegExp(
      /(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{6,}$/
    ).test(inputData.password);

    if (passRegEx || inputData.password === "") {
      setError((prevState) => {
        return {
          ...prevState,
          password: false,
        };
      });
    } else if (!passRegEx) {
      setError((prevState) => {
        return {
          ...prevState,
          password: true,
        };
      });
    }

    const passRetypeRegEx = new RegExp(
      /(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{6,}$/
    ).test(inputData.passwordRetype);

    if (passRetypeRegEx || inputData.passwordRetype === "") {
      setError((prevState) => {
        return {
          ...prevState,
          passwordRetype: false,
        };
      });
    } else if (!passRetypeRegEx) {
      setError((prevState) => {
        return {
          ...prevState,
          passwordRetype: true,
        };
      });
    }

    if (
      !passRegEx ||
      inputData.password === "" ||
      inputData.passwordRetype.length < 6
    ) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [inputData]);

  function handleChange(event) {
    //changing every field state
    const { name, value } = event.target;
    setInputData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const [blankError, setBlankError] = useState("");

  const password = inputData.password;

  function handleSubmit(event) {
    event.preventDefault();
    if (inputData.password !== inputData.passwordRetype) {
      setBlankError("Password does not match.");
    } else {
      sendResetPassPostReq();
    }
  }

  function sendResetPassPostReq() {
    axios
      .post(
        `${BASE_URL}/auth/reset-password?token=${tokenNum}&id=${idNum}`,
        { password },
        { headers: { "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed" } }
      )
      .then(function (response) {
        if (response.status === 200) {
          console.log(response);
          setEmailSent(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setBlankError("You cant use previews");
      });
  }

  return (
    <div className="container-fluid p-0" style={{ position: "relative" }}>
      <VipLogo></VipLogo>
      <Grid style={mainGridStyle}>
        <div className="container">
          <h1 style={logInStyle}>Change Password</h1>

          <div>
            <p>Please enter a new password.</p>
          </div>
          <div>
            <Grid style={inputGrid}>
              <form onSubmit={handleSubmit}>
                <div className="text-field-div ">
                  <TextField
                    error={error.password}
                    helperText={passwordHelperText}
                    variant="outlined"
                    style={textFieldStyle}
                    fullWidth
                    label="New password"
                    value={inputData.password}
                    name="password"
                    type="password"
                    onChange={handleChange}
                  ></TextField>
                  <TextField
                    error={error.passwordRetype}
                    helperText={passwordHelperText}
                    variant="outlined"
                    style={textFieldStyle}
                    fullWidth
                    label="Confirm password"
                    value={inputData.passwordRetype}
                    name="passwordRetype"
                    type="password"
                    onChange={handleChange}
                  ></TextField>
                </div>
              </form>
              <div>
                <p style={{ color: "red" }}>{blankError}</p>
              </div>

              <button
                disabled={btnDisabled}
                onClick={handleSubmit}
                className={btnClass}
              >
                Reset Password
              </button>
            </Grid>
          </div>
        </div>
      </Grid>
      <div style={footerStyle} className=""></div>
    </div>
  );
}

export default ForgotPassword;
