import React, { useContext } from "react"
import { Modal, Fade, makeStyles, Backdrop } from "@material-ui/core"
import { PredictionsContext } from "../contexts/PredictionsContext"
import axios from "axios"
import { BASE_URL } from "../environment"
import { ResultsScheduleContext } from "../contexts/ResultsScheduleContext"
import { CURRENT_CAMPAIGN_ENDPOINT } from "../leagues/leagues"
import { useHistory } from "react-router-dom"
import { isAndroid, isBrowser, isIOS } from "react-device-detect"
import ReactGA from "react-ga"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "20px",
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container_btn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "20px",
  },
  btn: {
    "&:hover, &active, &focus": {
      backgroundColor: "#7ebc15",
      color: "white",
    },
    width: "80px",
    height: "30px",
    border: "1px solid #7ebc15",
    borderRadius: "20px",
    color: "#7ebc15",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))
const ModalSubmit = ({ roundId, ready, setReady, afterClose }) => {
  const username = sessionStorage.getItem("username")

  const { apiCallWasMade, setApiCallWasMade, setModalActive, setIsDataOld, } = useContext(
    ResultsScheduleContext
  )
  const {
    setModify,
    predictionArray,
    currentLeague,
    english_RoundNumber_NextRound,
    spanish_RoundNumber_NextRound,
  } = useContext(PredictionsContext)
  const classes = useStyles()
  const history = useHistory()

  const handleClose = () => setReady(false)

  const predictionReq = {
    email: "blabla@gmail.com",
    roundId: roundId,
    predictions: predictionArray,
  }

  const handleOk = () => {
    axios
      .put(
        `${BASE_URL}/api/edit-predictions`,
        predictionReq,

        {
          headers: {
            "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          
          if (sessionStorage.getItem("os") === "ios") {
            console.log("ios edit")
            ReactGA.event({
              category: "Edit Success - IOS",
              action: `${username} made an edit from IOS.`,
            })
          } else if (sessionStorage.getItem("os") === "android") {
            console.log("android edit")
            ReactGA.event({
              category: "Edit Success - ANDROID",
              action: `${username} made an edit from ANDROID.`,
            })
          } else {
            console.log("isBrowser")
            ReactGA.event({
              category: "Edit Success - BROWSER",
              action: `${username} made an edit from BROWSER.`,
            })
          }
          setModify(false)
          setApiCallWasMade(!apiCallWasMade)
          setIsDataOld(true);
          setModalActive(false)
          if (afterClose) {
            afterClose()
            return;
          }
          history.push(`/${CURRENT_CAMPAIGN_ENDPOINT}/1x2`)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={ready}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={ready}>
        <div className={classes.paper}>
          <h6 className="text-center" id="transition-modal-title">
            Jornada{" "}
            {english_RoundNumber_NextRound || spanish_RoundNumber_NextRound}
          </h6>
          <p id="transition-modal-description">¿Quieres guardar los cambios?</p>
          <div className={classes.container_btn}>
            <div onClick={handleClose} className={classes.btn}>
              <p className="pt-3">NO</p>
            </div>
            <div onClick={handleOk} className={classes.btn}>
              <p className="pt-3">SÍ</p>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default ModalSubmit
