import SuccessPoints from "./../enums/success-points"

function MatchSuccessPoints({ points }) {
  return (
    <div
      className={`match-success-points-container ${
        points === SuccessPoints.CorrectScore
          ? "match-success-points-container__correct-score"
          : "match-success-points-container__correct-result"
      }`}
    >
      <div style={{ fontSize: "1.2rem", fontWeight: 600 }}>{points}</div>
      <div style={{ fontSize: ".5rem", fontWeight: 200 }}>PUNTOS</div>
      <div style={{ fontSize: ".6rem", fontWeight: 400 }}>
        {points === SuccessPoints.CorrectScore ? (
          <span>Resultado Exacto</span>
        ) : (
          <span>Resultado Correcto</span>
        )}
      </div>
    </div>
  )
}

export default MatchSuccessPoints
