import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
// import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import tabs from "./tabs.css";
import { PredictionsContext } from "../contexts/PredictionsContext";
import { CURRENT_CAMPAIGN_ENDPOINT } from "../leagues/leagues";

const useStyles = makeStyles((theme) => ({
  customTwo: {
    fonWeight: 1000,
    fontSize: 12,
  },
}));

const linkStyle = { textDecoration: "none", color: "rgba(53, 61, 73, 1)" };

export default function MatchesTabs({ bigScreen }) {
  const {
    spanish_RoundNumber_CurrentRound,
    spanish_RoundNumber_NextRound,
    english_RoundNumber_CurrentRound,
    english_RoundNumber_NextRound,
    spanish_RoundNumber_LastRound,
  } = useContext(PredictionsContext);
  const location = useLocation();

  const classes = useStyles();
  const theme = useTheme();
  const [selectedTab, setTabSelected] = React.useState(0);

  //fix of tab indicator bug
  useEffect(() => {
    if (location.pathname === "/premier-league") {
      setTabSelected(1);
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setTabSelected(newValue);
  };

  const handleChangeIndex = (index) => {
    setTabSelected(index);
  };

  const routes = [CURRENT_CAMPAIGN_ENDPOINT];

  return (
    <div
      className={`container-fluid p-0 ${
        bigScreen
          ? "tabs-container-light matches-desktop-specific"
          : "tabs-container"
      } matches-specific`}
    >
      <div color="default">
        <Tabs
          TabIndicatorProps={{
            style: {
              height: 3,
              background: "rgba(121, 192, 0, 1)",
              textDecoration: "none",
            },
          }}
          value={selectedTab}
          onChange={handleChange}
          textColor="primary"
          variant="fullWidth"
          aria-label="tabs"
        >
          {/* <Tab
            style={linkStyle}
            label="LIGA MX"
            component={Link}
            to={routes[0]}
          /> */}
          <Tab
            style={linkStyle}
            label={
              location.pathname === `/${CURRENT_CAMPAIGN_ENDPOINT}`
                ? spanish_RoundNumber_CurrentRound ||
                  spanish_RoundNumber_NextRound ||
                  spanish_RoundNumber_LastRound
                : english_RoundNumber_CurrentRound ||
                  english_RoundNumber_NextRound
            }
            component={Link}
            to={routes[0]}
          />

          {/* <Tab label="PREMIER-LEAGUE" component={Link} to={routes[1]} /> */}
        </Tabs>
      </div>
    </div>
  );
}
