import React, { useEffect } from "react"
import PropTypes from "prop-types"
// import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { Link } from "react-router-dom"
import { useLocation, useParams } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  customTwo: {
    fonWeight: 1000,
    fontSize: 12,
  },
}))

export default function AccumulatorTabs({ league }) {
  useEffect(() => {}, [league])
  const location = useLocation()

  const classes = useStyles()
  const theme = useTheme()
  const [selectedTab, setTabSelected] = React.useState(0)

  //fix of tab indicator bug
  //   useEffect(() => {
  //     if (location.pathname === "/premier-league") {
  //       setTabSelected(1);
  //     }
  //   }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setTabSelected(newValue)
  }

  const handleChangeIndex = (index) => {
    setTabSelected(index)
  }

  const param = useParams()

  const routes = [
    `/${league}/1x2`,
    `/${league}/u-o-2.5-goals`,
    `/${league}/bbts`,
  ]

  return (
    <div className="container-fluid p-0 tabs-container accumulator-specific accumulator-tabs">
      <div color="default">
        <Tabs
          TabIndicatorProps={{
            style: { height: 3, background: "rgba(121, 192, 0, 1)" },
          }}
          value={selectedTab}
          onChange={handleChange}
          textColor="primary"
          variant="fullWidth"
          aria-label="tabs"
        >
          <Tab label="1x2" component={Link} to={routes[0]} />
          <Tab label="MÁS/MENOS 2.5 GOLES" component={Link} to={routes[1]} />
          <Tab label="AMBOS MARCAN" component={Link} to={routes[2]} />
        </Tabs>
      </div>
    </div>
  )
}
